import { createSelector } from 'reselect';
import { AppState } from 'src/store/state';
import { AuthState } from './state';

const selectFeature = (state: AppState): AuthState => state.auth;

export class AuthSelectors {
  public static isAuthenticated = createSelector(
    selectFeature,
    (state) => state.isAuthenticated
  );

  public static isAuthenticating = createSelector(
    selectFeature,
    (state) => state.isAuthenticating
  );
}
