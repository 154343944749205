import { Transform } from 'class-transformer';
import { DateTime } from 'luxon';

export function TransformDate(withTime: boolean = true): Function {
  const toPlain = Transform(
    ({ value }): string | undefined => {
      if (DateTime.isDateTime(value)) {
        const utcValue = value.toUTC();
        return withTime ? utcValue.toISO() : utcValue.toISODate();
      }
    },
    { toPlainOnly: true }
  );

  const toClass = Transform(
    ({ value }): DateTime | undefined => {
      if (typeof value === 'string') {
        const date = DateTime.fromISO(value);

        if (date.isValid) {
          return date.toLocal();
        }
      }
    },
    { toClassOnly: true }
  );

  return (target: any, key: string): void => {
    toPlain(target, key);
    toClass(target, key);
  };
}
