import { Action, createReducer } from 'deox';
import { SignUpPageActions } from './actions';
import { SignUpPageState } from './state';
import { classToPlain } from 'class-transformer';
import { DTOGroup } from '../../../enums/dto-group';

const initialState = new SignUpPageState();

const reducer = createReducer(initialState, (handleAction) => [
  handleAction(SignUpPageActions.resetState, () => initialState),
  handleAction(SignUpPageActions.register, (state) => ({
    ...state,
    error: null,
    isSubmitting: true
  })),
  handleAction(SignUpPageActions.registerSuccess, (state) => ({
    ...state,
    isSubmitting: false
  })),
  handleAction(SignUpPageActions.registerFailure, (state, { payload }) => ({
    ...state,
    error: classToPlain(payload.error, { groups: [DTOGroup.MAIN], strategy: 'excludeAll' }),
    isSubmitting: false
  }))
]);

export function signUpPageReducer(state: SignUpPageState | undefined, action: Action<any>): SignUpPageState {
  return reducer(state, action);
}
