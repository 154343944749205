import { Action, createReducer } from 'deox';
import { ProfileActions } from './actions';
import { ProfileState } from './state';
import { classToPlain } from 'class-transformer';
import { DTOGroup } from '../../enums/dto-group';
import { AppActions } from '../actions';

const initialState = new ProfileState();

const reducer = createReducer(initialState, (handleAction) => [
  handleAction(AppActions.hydrate, (state, { payload }) => ({
    ...state,
    isAgreementSigned: payload.profile.isAgreementSigned
  })),
  handleAction(ProfileActions.refresh, (state) => ({
    ...state,
    isRefreshing: true
  })),
  handleAction(ProfileActions.refreshSuccess, (state) => ({
    ...state,
    isRefreshing: false
  })),
  handleAction(ProfileActions.refreshFailure, (state) => ({
    ...state,
    isRefreshing: false
  })),
  handleAction(ProfileActions.upsertState, (state, action) => ({
    ...state,
    profile: {
      ...state.profile,
      ...classToPlain(action.payload.profile, { groups: [DTOGroup.MAIN], strategy: 'excludeAll' })
    }
  })),
  handleAction(ProfileActions.clearState, (state) => ({
    ...state,
    profile: null
  })),
  handleAction(ProfileActions.setIsAgreementSigned, (state, { payload }) => ({
    ...state,
    isAgreementSigned: payload.isAgreementSigned
  })),
  handleAction(ProfileActions.checkSignedAgreementSuccess, (state, { payload }) => ({
    ...state,
    isAgreementSigned: payload.response.isAgreementSigned
  })),
  handleAction(ProfileActions.checkSignedAgreementFailure, (state, { payload }) => ({
    ...state,
    error: classToPlain(payload.error, { groups: [DTOGroup.MAIN], strategy: 'excludeAll' })
  }))
]);

export function profileReducer(state: ProfileState | undefined, action: Action<any>): ProfileState {
  return reducer(state, action);
}
