import { Navigation } from 'src/entities/navigation/navigation';
import { ServerError } from 'src/entities/server-errors';
import { action, actionWithPayload } from 'src/store/action-factory';

export class NavigationActions {
  public static resetState = action(
    '[Navigation] Reset state'
  );

  public static loadFooterMenu = action(
    '[Navigation] Load Footer Menu'
  );

  public static loadFooterMenuForServer = action(
    '[Navigation] Load Footer Menu for Server'
  );

  public static loadFooterMenuSuccess = actionWithPayload<{ response: Navigation }>(
    '[Navigation] Load Footer Menu Success'
  );

  public static loadFooterMenuFailure = actionWithPayload<{ error: ServerError<any> }>(
    '[Navigation] Load Footer Menu Failure'
  );

  public static loadLeftMenu = action(
    '[Navigation] Load Left Menu'
  );

  public static loadLeftMenuForServer = action(
    '[Navigation] Load Left Menu for Server'
  );

  public static loadLeftMenuSuccess = actionWithPayload<{ response: Navigation }>(
    '[Navigation] Load Left Menu Success'
  );

  public static loadLeftMenuFailure = actionWithPayload<{ error: ServerError<any> }>(
    '[Navigation] Load Left Menu Failure'
  );

  public static loadHomeMenu = action(
    '[Navigation] Load Home Menu'
  );

  public static loadHomeMenuForServer = action(
    '[Navigation] Load Home Menu for Server'
  );

  public static loadHomeMenuSuccess = actionWithPayload<{ response: Navigation }>(
    '[Navigation] Load Home Menu Success'
  );

  public static loadHomeMenuFailure = actionWithPayload<{ error: ServerError<any> }>(
    '[Navigation] Load Home Menu Failure'
  );
}
