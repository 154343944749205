import { Action, createReducer } from 'deox';
import { NavigationState } from './state';
import { classToPlain } from 'class-transformer';
import { DTOGroup } from 'src/enums/dto-group';
import { AppActions } from 'src/store/actions';
import { NavigationActions } from './actions';

const initialState = new NavigationState();

const reducer = createReducer(initialState, (handleAction) => [
  handleAction(NavigationActions.resetState, () => initialState),
  handleAction(AppActions.hydrate, (state, { payload }) => ({
    ...state,
    footerMenu: payload.navigation.footerMenu,
    leftMenu: state.leftMenu.length > 0 ? state.leftMenu : payload.navigation.leftMenu,
    homeMenu: payload.navigation.homeMenu
  })),
  handleAction(NavigationActions.loadFooterMenu, (state) => ({
    ...state,
    isFooterMenuLoading: true
  })),
  handleAction(NavigationActions.loadFooterMenuSuccess, (state, { payload }) => ({
    ...state,
    footerMenu: classToPlain(payload.response, { groups: [DTOGroup.MAIN], strategy: 'excludeAll' }) as Array<
      Record<string, any>
    >,
    isFooterMenuLoading: false
  })),
  handleAction(NavigationActions.loadFooterMenuFailure, (state) => ({
    ...state,
    isFooterLoading: false
  })),
  handleAction(NavigationActions.loadLeftMenu, (state) => ({
    ...state,
    isLeftMenuLoading: true,
    leftMenu: []
  })),
  handleAction(NavigationActions.loadLeftMenuSuccess, (state, { payload }) => ({
    ...state,
    leftMenu: classToPlain(payload.response, { groups: [DTOGroup.MAIN], strategy: 'excludeAll' }) as Array<
      Record<string, any>
    >,
    isLeftMenuLoading: false
  })),
  handleAction(NavigationActions.loadLeftMenuFailure, (state) => ({
    ...state,
    isLeftMenuLoading: false
  })),
  handleAction(NavigationActions.loadHomeMenu, (state) => ({
    ...state,
    isHomeMenuLoading: true
  })),
  handleAction(NavigationActions.loadHomeMenuSuccess, (state, { payload }) => ({
    ...state,
    homeMenu: classToPlain(payload.response, { groups: [DTOGroup.MAIN], strategy: 'excludeAll' }) as Array<
      Record<string, any>
    >,
    isHomeMenuLoading: false
  })),
  handleAction(NavigationActions.loadHomeMenuFailure, (state) => ({
    ...state,
    isHomeMenuLoading: false
  }))
]);

export function navigationReducer(state: NavigationState | undefined, action: Action<any>): NavigationState {
  return reducer(state, action);
}
