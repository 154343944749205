const path = require('path');

const resources = {
  en: {
    common: require('./public/i18n/en/common.json'),
    'components:common:featured-listing-preview': require('./public/i18n/en/components/common/featured-listing-preview.json'),
    'components:layout': require('./public/i18n/en/components/layout/layout.json'),
    'components:pages:home:title': require('./public/i18n/en/components/pages/home/title.json'),
    'components:pages:home:mineral-buyer': require('./public/i18n/en/components/pages/home/mineral-buyer.json'),
    'components:pages:home:review-box': require('./public/i18n/en/components/pages/home/review-box.json'),
    'components:pages:home:video-box': require('./public/i18n/en/components/pages/home/video-box.json'),
    'components:pages:sign-up:advantages': require('./public/i18n/en/components/pages/sign-up/advantages.json'),
    'components:pages:sign-up:create-account': require('./public/i18n/en/components/pages/sign-up/create-account.json'),
    'components:pages:profile:edit-account': require('./public/i18n/en/components/pages/profile/edit-account.json'),
    'components:pages:profile:change-password': require('./public/i18n/en/components/pages/profile/change-password.json'),
    'components:pages:profile:email-settings': require('./public/i18n/en/components/pages/profile/email-settings.json'),
    'components:pages:listings:filters': require('./public/i18n/en/components/pages/listings/filters.json'),
    'components:pages:listings:table': require('./public/i18n/en/components/pages/listings/table.json'),
    'components:pages:listings:return-back': require('./public/i18n/en/components/pages/listings/return-back.json'),
    'components:pages:restore-password': require('./public/i18n/en/components/pages/restore-password.json'),
    'components:ui-kit:pagination': require('./public/i18n/en/components/ui-kit/pagination.json'),
    'components:pages:contact': require('./public/i18n/en/components/pages/contact/contact.json'),
    'components:pages:listings:view:maps': require('./public/i18n/en/components/pages/listings/view/maps.json'),
    'components:pages:listings:view:details': require('./public/i18n/en/components/pages/listings/view/details.json'),
    'components:pages:listings:view:banner': require('./public/i18n/en/components/pages/listings/view/banner.json'),
    'components:pages:listings:view:documents': require('./public/i18n/en/components/pages/listings/view/documents.json'),
    'components:pages:contact-support': require('./public/i18n/en/components/pages/contact-support.json'),
    'components:pages:listings:inquiry': require('./public/i18n/en/components/pages/listings/inquiry/inquiry.json'),
    'components:pages:listings:inquiry:details': require('./public/i18n/en/components/pages/listings/inquiry/details.json'),
    'components:pages:listings:inquiry:form': require('./public/i18n/en/components/pages/listings/inquiry/form.json'),
    'components:pages:seller-portal': require('./public/i18n/en/components/pages/seller-portal/seller-portal.json'),
    'components:pages:seller-portal:important-information': require('./public/i18n/en/components/pages/seller-portal/important-information.json'),
    'components:pages:listings:view:banner-create-account': require('./public/i18n/en/components/pages/listings/view/banner-create-account.json'),
    'components:pages:sign-access-agreement': require('./public/i18n/en/components/pages/sign-access-agreement/sign-access-agreement.json'),
    'components:pages:sign-access-agreement:form': require('./public/i18n/en/components/pages/sign-access-agreement/form.json'),
    'components:pages:sign-in': require('./public/i18n/en/components/pages/sign-in.json'),
    'components:pages:terms': require('./public/i18n/en/components/pages/terms.json'),
    'components:pages:privacy-policy': require('./public/i18n/en/components/pages/privacy-policy.json'),
    'components:pages:enter-new-password': require('./public/i18n/en/components/pages/enter-new-password.json'),
    'components:pages:upload-files': require('./public/i18n/en/components/pages/upload-files.json'),
    'components:ui-kit:file-upload-area': require('./public/i18n/en/components/ui-kit/file-upload-area.json'),
    'components:pages:listings:banner-approval': require('./public/i18n/en/components/pages/listings/banner-approval.json'),
    'components:pages:listings:view': require('./public/i18n/en/components/pages/listings/view/view.json'),
    'components:pages:unsubscribe': require('./public/i18n/en/components/pages/unsubscribe.json')
  }
};

module.exports = {
  i18n: {
    resources,
    locales: ['en'],
    defaultLocale: 'en',
    debug: false,
    defaultNS: 'common',
    localePath: path.resolve('public/i18n')
  }
};
