import { Action } from 'deox';
import { authService } from 'src/services/auth';
import { AuthActions } from 'src/store/auth';
import { ProfileActions } from 'src/store/profile/actions';
import { SignUpPageActions } from './actions';
import { SagaIterator } from 'redux-saga';
import { takeLatest, call, put } from 'typed-redux-saga';
import Router from 'next/router';
import { toast } from 'react-toastify';
import { i18n as i18next } from 'next-i18next';
import { i18n } from 'next-i18next.config';
import { RegisterData } from 'src/entities/user';

function* register({ payload }: Action<string, typeof SignUpPageActions.register.payload>): SagaIterator {
  try {
    const response = yield* call(authService.register, new RegisterData(payload.registerData));

    yield* put(SignUpPageActions.registerSuccess({ response }));
    yield* put(ProfileActions.upsertState({ profile: response.user }));
    yield* put(AuthActions.authorizeSuccess({ response }));

    const t = i18next?.getFixedT(i18n.defaultLocale, 'components:pages:sign-up:create-account');
    if (t) {
      toast.success(t('TEXT_YOU_HAVE_SUCCESSFULLY_REGISTERED') as string);
    }

    Router.push('/api/preview');
  } catch (error) {
    yield* put(SignUpPageActions.registerFailure({ error }));
  }
}

export default function* signUpPageSaga(): SagaIterator {
  yield* takeLatest(SignUpPageActions.register, register);
}
