import classNames from 'classnames/bind';
import Icon, { IconTheme, IconVariant } from 'src/components/ui-kit/icon/icon';
import styles from './layout.module.scss';
import { PropsWithChildren } from 'react';
import { useMediaQuery } from '@react-hooks-library/core';

const classesContext = classNames.bind(styles);

export default function LayoutSidebarItem({
  children,
  iconVariant,
  isActive,
  href,
  ...restProps
}: PropsWithChildren<{ iconVariant: IconVariant; href: string; isActive: boolean }>): JSX.Element {
  const isTablet = useMediaQuery(`(max-width: ${styles.tablet})`);

  const iconTheme = isActive ? IconTheme.PRIMARY : isTablet ? IconTheme.BLACK : IconTheme.WHITE;

  return (
    <a
      href={href}
      className={classesContext(['sidebar_item', { active: isActive }])}
      {...restProps}>
      <span className={styles.sidebar_icon}>
        <Icon
          variant={iconVariant}
          theme={iconTheme} />
      </span>
      <span className={classesContext(['sidebar_link'])}>{children}</span>
    </a>
  );
}
