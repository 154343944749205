import { Listing } from 'src/entities/listing';
import { ServerError } from 'src/entities/server-errors';
import { ListingRelation } from 'src/enums/listing';
import { action, actionWithPayload } from 'src/store/action-factory';

export class ListingViewPageActions {
  public static resetState = action(
    '[ListingViewPage] Reset state'
  );

  public static loadListingView = actionWithPayload<{
    id: number;
    relations?: Array<ListingRelation>;
  }>(
    '[ListingViewPage] Load Listing View'
  );

  public static loadListingViewForServer = actionWithPayload<{
    id: number;
  }>(
    '[ListingViewPage] Load Listing View for Server'
  );

  public static loadListingViewSuccess = actionWithPayload<{ response: Listing }>(
    '[ListingViewPage] Load Listing View Success'
  );

  public static loadListingViewFailure = actionWithPayload<{ error: ServerError<any> }>(
    '[ListingViewPage] Load Listing View Failure'
  );
}
