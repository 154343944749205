import Layout from '../components/layout/layout';
import type { AppProps } from 'next/app';
import hoistNonReactStatics, { NonReactStatics } from 'hoist-non-react-statics';
import { ComponentClass, ComponentType, ElementType, FunctionComponent } from 'react';

export const appWithLayout = (
  WrappedComponent: ComponentType<AppProps> | ElementType<AppProps>
): ((
  props: AppProps
) => JSX.Element & NonReactStatics<ComponentClass<AppProps, any> | FunctionComponent<AppProps>>) => {
  const AppWithLayout = (props: AppProps): JSX.Element => {
    return (
      <Layout>
        <WrappedComponent {...props} />
      </Layout>
    );
  };

  return hoistNonReactStatics(AppWithLayout, WrappedComponent);
};
