import Router from 'next/router';
import { authService } from 'src/services/auth';
import { EnterNewPasswordPageActions } from './actions';
import { Action } from 'deox';
import { SagaIterator } from 'redux-saga';
import { takeLatest, call, put, FixedTask, fork } from 'typed-redux-saga';
import { toast } from 'react-toastify';
import { i18n as i18next } from 'next-i18next';
import { i18n } from 'next-i18next.config';

export let checkTokenTask: FixedTask<typeof checkToken>;

function* restorePassword({
  payload
}: Action<string, typeof EnterNewPasswordPageActions.restorePassword.payload>): SagaIterator {
  try {
    yield* call(authService.restorePassword, payload);

    Router.push('/users/login');

    const t = i18next?.getFixedT(i18n.defaultLocale, 'components:pages:enter-new-password');
    if (t) {
      toast.success(t('TEXT_MESSAGE_SUCCESS') as string);
    }

    yield* put(EnterNewPasswordPageActions.restorePasswordSuccess());
  } catch (error) {
    yield* put(EnterNewPasswordPageActions.restorePasswordFailure({ error }));
  }
}

function* checkToken({ payload }: Action<string, typeof EnterNewPasswordPageActions.checkToken.payload>): SagaIterator {
  try {
    yield* call(authService.checkToken, payload.token);

    yield* put(EnterNewPasswordPageActions.checkTokenSuccess());
  } catch (error) {
    yield* put(EnterNewPasswordPageActions.checkTokenFailure({ error }));
  }
}

function* checkTokenForServer(action: Action<string, { token: string }>): SagaIterator {
  yield* call(function* () {
    checkTokenTask = yield* fork(checkToken, action);
  });
}

export default function* enterNewPasswordPageSaga(): SagaIterator {
  yield* takeLatest(EnterNewPasswordPageActions.restorePassword, restorePassword);
  yield* takeLatest(EnterNewPasswordPageActions.checkToken, checkToken);
  yield* takeLatest(EnterNewPasswordPageActions.checkTokenForServer, checkTokenForServer);
}
