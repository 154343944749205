import { Exclude, Expose, Type } from 'class-transformer';

export class ServerError<T = {}> extends Error {
  @Expose()
  public message: string;

  @Expose()
  @Type((options) => {
    return (options?.newObject as ServerError<T>)?.type;
  })
  public errors: Partial<T>;

  @Exclude()
  private type: Function;

  constructor(type: Function) {
    super('Server error');

    this.type = type;
  }
}
