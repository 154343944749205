import classNames from 'classnames/bind';
import Icon, { IconVariant } from 'src/components/ui-kit/icon/icon';
import { useTranslation } from 'next-i18next';
import logoImage from 'public/images/logo.svg';
import { DetailedHTMLProps, HTMLAttributes } from 'react';
import styles from './layout.module.scss';
import LayoutLogin from './_login';
import Link from 'next/link';
import Image from 'next/image';
import LayoutHeaderLinks from './_header_links';

const classesContext = classNames.bind(styles);

export default function LayoutHeader({
  className,
  onToggleMobileMenu,
  onOpenFullScreenSearch,
  isFullScreenSearchOpen,
  isMenuOpen,
  ...restProps
}: {
  onToggleMobileMenu: () => void;
  onOpenFullScreenSearch: () => void;
  isFullScreenSearchOpen: boolean;
  isMenuOpen: boolean;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>): JSX.Element {
  const { t } = useTranslation(['components:layout']);

  return (
    <div
      className={classesContext(['account_header', { account_header_search: isFullScreenSearchOpen }, className])}
      {...restProps}
    >
      <div className={classesContext(['account_header', 'header'])}>
        <div className={classesContext('header_wrapper')}>
          <div className={styles.account_header__inner}>
            <Link href='/'>
              <a className={styles.header_logo}>
                <Image
                  alt={t('HEADER.TEXT_LOGO')}
                  width='365'
                  height='82'
                  src={logoImage} />
              </a>
            </Link>
            <div className={classesContext('header_wrapper__action')}>
              {!isFullScreenSearchOpen && (
                <button
                  onClick={onOpenFullScreenSearch}
                  className={styles.header_wrapper__action_item}>
                  <Icon variant={IconVariant.SEARCH} />
                </button>
              )}
              <button
                onClick={onToggleMobileMenu}
                className={styles.header_wrapper__action_item}>
                <Icon variant={isMenuOpen || isFullScreenSearchOpen ? IconVariant.CLOSE : IconVariant.MENU} />
              </button>
            </div>
          </div>
          <div className={classesContext('header_login')}>
            <div className={styles.header_login_inner}>
              <LayoutLogin />
            </div>
            <div className={styles.header_login_links}>
              <LayoutHeaderLinks />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
