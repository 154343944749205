import { classToPlain, plainToClass, plainToClassFromExist } from 'class-transformer';
import { DTOGroup } from 'src/enums/dto-group';
import { apiService } from './api';
import { ServerError } from 'src/entities/server-errors';
import { UploadedFilesData } from 'src/entities/uploaded-files/uploaded-files';

export class UploadedFilesService {
  public async uploadFile(mediaID: UploadedFilesData): Promise<UploadedFilesData> {
    const data = classToPlain<UploadedFilesData>(mediaID, { groups: [DTOGroup.CREATE], strategy: 'excludeAll' });

    try {
      const response = await apiService.post<UploadedFilesData>('/uploaded-files', data);

      return plainToClass(UploadedFilesData, response, { groups: [DTOGroup.CREATE] });
    } catch (error) {
      throw plainToClassFromExist(new ServerError<UploadedFilesData>(UploadedFilesData), error, {
        groups: [DTOGroup.CREATE],
        strategy: 'excludeAll'
      });
    }
  }
}

export const uploadedFilesService = new UploadedFilesService();
