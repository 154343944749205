import { FileItem } from 'src/types/file-item';

export class SellerPortalPageState {
  public isSending: boolean;
  public isSubmitted: boolean;
  public error: object | null;
  public files: Array<FileItem>;

  constructor() {
    this.isSending = false;
    this.isSubmitted = false;
    this.error = null;
    this.files = [];
  }
}
