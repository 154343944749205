import { ServerError } from 'src/entities/server-errors';
import { action, actionWithPayload } from 'src/store/action-factory';

export class ServerErrorMessageActions {
  public static removeMessage = action(
    '[ServerErrorMessages] Remove Message'
  );

  public static setMessage = actionWithPayload<{ error: ServerError }>(
    '[ServerErrorMessages] Set Message'
  );
}
