import classNames from 'classnames/bind';
import Button, { ButtonProps } from './button';
import styles from './button.module.scss';

const classesContext = classNames.bind(styles);

export default function ButtonIcon({ className, ref, ...restProps }: ButtonProps): JSX.Element {
  return <Button
    className={classesContext(['button__icon', className])}
    {...restProps} />;
}
