import { plainToClass } from 'class-transformer';
import { Media } from 'src/entities/media';
import { DTOGroup } from 'src/enums/dto-group';
import { apiService } from './api';

const endpoint = '/media';

export class MediaService {
  public async getByID(id: number): Promise<Media> {
    const response = await apiService.get<Media>(`${endpoint}/${id}`);

    return plainToClass(Media, response, {
      groups: [DTOGroup.MAIN],
      strategy: 'excludeAll'
    });
  }

  public async upload(file: File, onUploadProgress?: (progressEvent: ProgressEvent) => void): Promise<Media> {
    const data = new FormData();
    data.append('file', file);

    const response = await apiService.post<File>(endpoint, data, { onUploadProgress });

    return plainToClass(Media, response, {
      groups: [DTOGroup.MAIN],
      strategy: 'excludeAll'
    });
  }
}

export const mediaService = new MediaService();
