import { Listing } from 'src/entities/listing';
import { ListingFiltersQueryParameters } from 'src/entities/listing/query-filters-parameters';
import { PaginationResponse } from 'src/entities/pagination';
import { ServerError } from 'src/entities/server-errors';
import { State } from 'src/entities/state';
import { ListingOrderBy, ListingRelation } from 'src/enums/listing';
import { action, actionWithPayload } from 'src/store/action-factory';

export class MineralListingsPageActions {
  public static resetState = action(
    '[ListingsPage] Reset state'
  );

  public static loadListings = actionWithPayload<{
    page?: number;
    perPage?: number;
    relations?: Array<ListingRelation>;
    order?: ListingOrderBy;
    desc?: boolean;
    filters?: ListingFiltersQueryParameters;
  }>(
    '[ListingsPage] Load Listings'
  );

  public static loadListingsForServer = actionWithPayload<{
    page: number;
    order?: ListingOrderBy;
    desc?: boolean;
    stateID?: number;
    countyID?: number;
  }>(
    '[ListingsPage] Load Listings for Server'
  );

  public static loadListingsSuccess = actionWithPayload<{ response: PaginationResponse<Listing> }>(
    '[ListingsPage] Load Listings Success'
  );

  public static loadListingsFailure = actionWithPayload<{ error: ServerError<any> }>(
    '[ListingsPage] Load Listings Failure'
  );

  public static loadStates = action(
    '[ListingsPage] Load States'
  );

  public static loadStatesForServer = action(
    '[ListingsPage] Load States for Server'
  );

  public static loadStatesSuccess = actionWithPayload<{ response: PaginationResponse<State> }>(
    '[ListingsPage] Load States Success'
  );

  public static loadStatesFailure = actionWithPayload<{ error: ServerError<any> }>(
    '[ListingsPage] Load States Failure'
  );

  public static setPerPage = actionWithPayload<{ perPage: number }>(
    '[ListingsPage] Set Per Page'
  );
}
