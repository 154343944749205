import { classToPlain, plainToClass, plainToClassFromExist } from 'class-transformer';
import { DTOGroup } from 'src/enums/dto-group';
import { ServerError } from 'src/entities/server-errors';
import { SignAgreementData } from 'src/entities/sign-agreement';
import { apiService } from './api';

export class SignAgreementService {
  public async signAgreement(agreement: SignAgreementData): Promise<SignAgreementData> {
    try {
      const response = await apiService.post<SignAgreementData>(
        '/legal-agreements',
        classToPlain<SignAgreementData>(agreement, {
          groups: [DTOGroup.CREATE],
          strategy: 'excludeAll'
        })
      );

      return plainToClass(SignAgreementData, response, { groups: [DTOGroup.MAIN], strategy: 'excludeAll' });
    } catch (error) {
      throw plainToClassFromExist(new ServerError<SignAgreementData>(SignAgreementData), error, {
        groups: [DTOGroup.CREATE],
        strategy: 'excludeAll'
      });
    }
  }
}

export const signAgreementService = new SignAgreementService();
