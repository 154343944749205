import styles from './spinner.module.scss';
import classNames from 'classnames/bind';

const classesContext = classNames.bind(styles);

export enum SpinnerSize {
  SMALL = 'small',
  NORMAL = 'normal',
  LARGE = 'large'
}

export enum SpinnerTheme {
  WHITE = 'white'
}

export default function Spinner({ size, theme }: { size?: SpinnerSize; theme?: SpinnerTheme }): JSX.Element {
  return <span className={classesContext(['spinner', `spinner__${size}`, `spinner__${theme}`])} />;
}
