import { SagaIterator } from 'redux-saga';
import { call, FixedTask, fork, put, takeLatest } from 'typed-redux-saga';
import { profileService } from 'src/services/profile';
import { ProfileActions } from './actions';
import { Action } from 'deox';
import { configuration } from '../../configurations/configuration';
import { destroyCookie, setCookie } from 'nookies';
import { SignAgreementResponse } from 'src/entities/user/sign-agreement-response';
import { userService } from 'src/services/user';

export let checkSignedAgreementTask: FixedTask<typeof SignAgreementResponse>;

function* refresh(): SagaIterator {
  try {
    const profile = yield* call(profileService.refresh);

    yield* put(ProfileActions.refreshSuccess({ profile }));
    yield* put(ProfileActions.upsertState({ profile }));
  } catch (error) {
    yield* put(ProfileActions.refreshFailure());
  }
}

function* upsertState({ payload }: Action<string, typeof ProfileActions.upsertState.payload>): SagaIterator {
  yield* call(
    {
      context: setCookie,
      fn: setCookie
    },
    null,
    configuration.cookies.keys.userName,
    payload.profile?.firstName ?? '',
    { path: '/' }
  );

  yield* call(
    {
      context: setCookie,
      fn: setCookie
    },
    null,
    configuration.cookies.keys.userId,
    payload.profile?.id?.toString() ?? '',
    { path: '/' }
  );
}

function* clearState(): SagaIterator {
  yield* call(
    {
      context: destroyCookie,
      fn: destroyCookie
    },
    null,
    configuration.cookies.keys.userName,
    { path: '/' }
  );

  yield* call(
    {
      context: destroyCookie,
      fn: destroyCookie
    },
    null,
    configuration.cookies.keys.userId,
    { path: '/' }
  );
}

function* checkSignedAgreement({
  payload
}: Action<string, typeof ProfileActions.checkSignedAgreementForServer.payload>): SagaIterator {
  try {
    const response = yield* call(userService.checkSignedAgreement, payload.userId);

    yield* put(ProfileActions.checkSignedAgreementSuccess({ response }));
  } catch (error) {
    yield* put(ProfileActions.checkSignedAgreementFailure({ error }));
  }
}

function* checkSignedAgreementForServer({
  type,
  payload
}: Action<string, typeof ProfileActions.checkSignedAgreementForServer.payload>): SagaIterator {
  yield* call(function* () {
    checkSignedAgreementTask = yield* fork(checkSignedAgreement, { type, payload });
  });
}

export default function* profileSaga(): SagaIterator {
  yield* takeLatest(ProfileActions.refresh, refresh);
  yield* takeLatest(ProfileActions.upsertState, upsertState);
  yield* takeLatest(ProfileActions.clearState, clearState);
  yield* takeLatest(ProfileActions.checkSignedAgreementForServer, checkSignedAgreementForServer);
}
