import classNames from 'classnames/bind';
import ButtonBlock from 'src/components/ui-kit/button/__block';
import FormTextInput from 'src/components/ui-kit/form/_text-input';
import { User } from 'src/entities/user';
import { useFormik } from 'formik';
import { LoginForm } from 'src/forms/login';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthActions, AuthSelectors } from 'src/store/auth';
import { ProfileSelectors } from 'src/store/profile/selectors';
import styles from './layout.module.scss';
import Icon, { IconTheme, IconVariant } from '../ui-kit/icon/icon';

const classesContext = classNames.bind(styles);

export default function LayoutLogin({
  className,
  ...restProps
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>): JSX.Element {
  const { t } = useTranslation(['components:layout']);

  const initialValues = new LoginForm();
  const validationSchema = LoginForm.getValidationSchema(t);

  const dispatch = useDispatch();
  const isAuthenticating = useSelector(AuthSelectors.isAuthenticating);
  const isAuthenticated = useSelector(AuthSelectors.isAuthenticated);
  const profile = useSelector(ProfileSelectors.profile);

  const handleSubmit = (values: LoginForm): void => {
    dispatch(AuthActions.authorize({ user: new User(values) }));
  };

  const logout = (): void => {
    dispatch(AuthActions.unauthorize());
  };

  const handlePress = (event: React.KeyboardEvent): void => {
    if (
      (event.code === 'Enter' || event.code === 'NumpadEnter') &&
      window.confirm(t('LOGIN.TEXT_DO_YOU_WANT_TO_LOGOUT'))
    ) {
      logout();
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema
  });

  return (
    <div
      className={classesContext(['login', className, { login__logined: isAuthenticated }])}
      {...restProps}>
      {!isAuthenticated && (
        <>
          <form
            onSubmit={formik.handleSubmit}
            className={styles.login_form}>
            <div className='row row__small'>
              <div className={classesContext('col col__40 col__mobile__100', styles.login_form_col)}>
                <FormTextInput<LoginForm>
                  name='userNameOrEmail'
                  formik={formik}
                  placeholder={t('LOGIN.FORM.TEXT_USER_NAME_OR_EMAIL')}
                  autoComplete='username'
                  hiddenError
                  required
                />
              </div>
              <div className={classesContext('col col__40 col__mobile__100', styles.login_form_col)}>
                <FormTextInput<LoginForm>
                  name='password'
                  formik={formik}
                  type='password'
                  placeholder={t('LOGIN.FORM.TEXT_PASSWORD')}
                  autoComplete='current-password'
                  hiddenError
                  required
                />
              </div>
              <div className={classesContext('col col__20 col__mobile__100', styles.login_form_col)}>
                <ButtonBlock
                  isLoading={isAuthenticating}
                  type='submit'>
                  {t('LOGIN.FORM.BUTTON_LOGIN')}
                  {!isAuthenticating && (
                    <span className={styles.login_accountcreate_icon}>
                      <Icon
                        variant={IconVariant.NEXT}
                        theme={IconTheme.WHITE} />
                    </span>
                  )}
                </ButtonBlock>
              </div>
            </div>
          </form>
          <div className='row row__small'>
            <div className={classesContext('col col__40', styles.login_accountcreate)}>
              <Link href='/blog/create-account-us-mineral-exchange'>
                <a className={classesContext('link', 'login_link', 'login_link__first')}>
                  {t('LOGIN.LINK_CREATE_ACCOUNT')}
                </a>
              </Link>
            </div>
            <div className={classesContext('col col__40 col__tablet__100', styles.login_forgotpassword)}>
              <Link href='/users/restorePassword'>
                <a className={classesContext('link', 'login_link')}>{t('LOGIN.LINK_FORGOT_PASSWORD')}</a>
              </Link>
            </div>
          </div>
        </>
      )}
      {isAuthenticated && (
        <>
          <span className={styles.login_subject}>
            {t('LOGIN.TEXT_WELCOME')}&nbsp;
            {profile?.firstName}
          </span>
          <a
            onClick={logout}
            onKeyDown={handlePress}
            role='button'
            tabIndex={0}
            className={classesContext(['link', 'login_link', 'login_link__logout'])}
          >
            {t('LOGIN.LINK_LOGOUT')}
          </a>
        </>
      )}
    </div>
  );
}
