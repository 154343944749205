import classNames from 'classnames/bind';
import Box from 'src/components/ui-kit/box/box';
import { DateTime } from 'luxon';
import logoImage from 'public/images/logo-bw.png';
import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { useTranslation } from 'next-i18next';
import styles from './layout.module.scss';
import LayoutFooterLink from './_footer_link';
import { NavigationSelectors } from 'src/store/navigation';
import { useSelector } from 'react-redux';
import { NavigationPosition } from 'src/enums/navigation/position';
import Image from 'next/image';

const classesContext = classNames.bind(styles);

export default function LayoutFooter({
  className,
  ...restProps
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>): JSX.Element {
  const { t } = useTranslation(['components:layout']);

  const currentYear = DateTime.utc().year;

  const footerMenu = useSelector(NavigationSelectors.footerMenu);

  return (
    <Box className={styles.account_footer_box}>
      <div
        className={classesContext(['account_footer', 'footer', className])}
        {...restProps}>
        <div className={classesContext(['footer_header', 'excludeformobile'])}>
          <div className='row'>
            <div className='col col__25 col__mobile__100'>
              <div className={classesContext(['footer_item'])}>{t('FOOTER.TEXT_HELP')}</div>
            </div>
            <div className='col col__25 col__mobile__100'>
              <div className={classesContext(['footer_item'])}>{t('FOOTER.TEXT_ACCOUNT')}</div>
            </div>
            <div className='col col__25 col__mobile__100'>
              <div className={classesContext(['footer_item'])}>{t('FOOTER.TEXT_COMPANY')}</div>
            </div>
          </div>
        </div>
        <div className={classesContext(['footer_body'])}>
          <div className='row'>
            <div className='col col__25 col__mobile__100'>
              <div className={classesContext(['footer_items'])}>
                <div className={classesContext(['footer_header', 'onlymobile'])}>
                  <div className={classesContext(['footer_item'])}>{t('FOOTER.TEXT_HELP')}</div>
                </div>
                {footerMenu.map(
                  (item) => item.position === NavigationPosition.FOOTER_HELP && (
                    <LayoutFooterLink
                      key={item.id}
                      label={item.name}
                      link={item.link} />
                  )
                )}
              </div>
            </div>
            <div className='col col__25 col__mobile__100'>
              <div className={classesContext(['footer_items'])}>
                <div className={classesContext(['footer_header', 'footer_header__noborder', 'onlymobile'])}>
                  <div className={classesContext(['footer_item'])}>{t('FOOTER.TEXT_ACCOUNT')}</div>
                </div>
                {footerMenu.map(
                  (item) => item.position === NavigationPosition.FOOTER_ACCOUNT && (
                    <LayoutFooterLink
                      key={item.id}
                      label={item.name}
                      link={item.link} />
                  )
                )}
              </div>
            </div>
            <div className='col col__25 col__mobile__100'>
              <div className={classesContext(['footer_items'])}>
                <div className={classesContext(['footer_header', 'footer_header__noborder', 'onlymobile'])}>
                  <div className={classesContext(['footer_item'])}>{t('FOOTER.TEXT_COMPANY')}</div>
                </div>
                {footerMenu.map(
                  (item) => item.position === NavigationPosition.FOOTER_COMPANY && (
                    <LayoutFooterLink
                      key={item.id}
                      label={item.name}
                      link={item.link} />
                  )
                )}
              </div>
            </div>
            <div className='col col__25 col__mobile__100'>
              <div className={styles.footer_logo}>
                <Image
                  alt={t('FOOTER.TEXT_LOGO')}
                  src={logoImage}
                  width='250'
                  height='53' />
              </div>
              <div className={styles.footer_copyright}>
                ©&nbsp;{currentYear}&nbsp;{t('FOOTER.TEXT_US_MINERAL_EXCHANGE')}
                <br />
                {t('FOOTER.TEXT_ALL_RIGHTS_RESERVED')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}
