import { Action, createReducer } from 'deox';
import { getUploadingFilesProgress } from 'src/utils/get-uploading-files-progress';
import { UploadFilesPageActions } from './actions';
import { UploadFilesPageState } from './state';

const initialState = new UploadFilesPageState();

const reducer = createReducer(initialState, (handleAction) => [
  handleAction(UploadFilesPageActions.resetState, () => initialState),
  handleAction(UploadFilesPageActions.uploadFileSuccess, (state, { payload }) => ({
    ...state,
    files: state.files.map((item) => item.file === payload.file ? { ...item, isUploaded: true, media: payload.media } : item)
  })),
  handleAction(UploadFilesPageActions.uploadFileProgress, (state, { payload }) => ({
    ...state,
    files: getUploadingFilesProgress(state.files, payload)
  })),
  handleAction(UploadFilesPageActions.uploadFileFailure, (state, { payload }) => ({
    ...state,
    files: state.files.filter((item) => item.file !== payload.file)
  })),
  handleAction(UploadFilesPageActions.deleteUploadFile, (state, { payload }) => ({
    ...state,
    files: state.files.filter((item) => item.file !== payload.file)
  }))
]);

export function uploadFilesPageReducer(
  state: UploadFilesPageState | undefined,
  action: Action<any>
): UploadFilesPageState {
  return reducer(state, action);
}
