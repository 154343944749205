import { Action, createReducer } from 'deox';
import { FeaturedListingsState } from './state';
import { FeaturedListingsActions } from './actions';
import { classToPlain } from 'class-transformer';
import { DTOGroup } from 'src/enums/dto-group';
import { AppActions } from 'src/store/actions';

const initialState = new FeaturedListingsState();

const reducer = createReducer(initialState, (handleAction) => [
  handleAction(FeaturedListingsActions.resetState, () => initialState),
  handleAction(AppActions.hydrate, (state, { payload }) => ({
    ...state,
    items: payload.featuredListings.items
  })),
  handleAction(FeaturedListingsActions.loadFeaturedListings, (state) => ({
    ...state,
    isLoading: true
  })),
  handleAction(FeaturedListingsActions.loadFeaturedListingsSuccess, (state, { payload }) => ({
    ...state,
    items: classToPlain(payload.response.data, { groups: [DTOGroup.MAIN], strategy: 'excludeAll' }) as Array<
      Record<string, any>
    >,
    isLoading: false
  })),
  handleAction(FeaturedListingsActions.loadFeaturedListings, (state) => ({
    ...state,
    isLoading: false
  }))
]);

export function featuredListingsReducer(
  state: FeaturedListingsState | undefined,
  action: Action<any>
): FeaturedListingsState {
  return reducer(state, action);
}
