import { Action, createReducer } from 'deox';
import { EnterNewPasswordPageActions } from './actions';
import { EnterNewPasswordPageState } from './state';
import { classToPlain } from 'class-transformer';
import { DTOGroup } from '../../../enums/dto-group';

const initialState = new EnterNewPasswordPageState();

const reducer = createReducer(initialState, (handleAction) => [
  handleAction(EnterNewPasswordPageActions.resetState, () => initialState),
  handleAction(EnterNewPasswordPageActions.restorePassword, (state) => ({
    ...state,
    error: null,
    isSending: true
  })),
  handleAction(EnterNewPasswordPageActions.restorePasswordSuccess, (state) => ({
    ...state,
    isSending: false
  })),
  handleAction(EnterNewPasswordPageActions.restorePasswordFailure, (state, { payload }) => ({
    ...state,
    error: classToPlain(payload.error, { groups: [DTOGroup.MAIN], strategy: 'excludeAll' }),
    isSending: false
  })),
  handleAction(EnterNewPasswordPageActions.checkTokenSuccess, (state) => ({
    ...state,
    error: null
  })),
  handleAction(EnterNewPasswordPageActions.checkTokenFailure, (state, { payload }) => ({
    ...state,
    error: classToPlain(payload.error, { groups: [DTOGroup.MAIN], strategy: 'excludeAll' })
  }))
]);

export function enterNewPasswordPageReducer(
  state: EnterNewPasswordPageState | undefined,
  action: Action<any>
): EnterNewPasswordPageState {
  return reducer(state, action);
}
