import { END, EventChannel, eventChannel } from 'redux-saga';
import { Media } from 'src/entities/media';
import { mediaService } from 'src/services/media';

export function createUploader(file: File): [Promise<Media>, EventChannel<number | END>] {
  let emit: (input: number | END) => void;
  const channelUploadFile = eventChannel<number | END>((emitter) => {
    emit = emitter;
    return () => ({});
  });

  const uploadProgress = ({ total, loaded }: { total: number; loaded: number }): void => {
    const percentage = Math.round((loaded * 100) / total);
    emit(percentage);

    if (percentage === 100) {
      emit(END);
    }
  };

  const uploadPromise = mediaService.upload(file, uploadProgress);

  return [uploadPromise, channelUploadFile];
}
