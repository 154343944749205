import { Action, createReducer } from 'deox';
import { ProfilePageActions } from './actions';
import { ProfilePageState } from './state';
import { classToPlain } from 'class-transformer';
import { DTOGroup } from '../../../enums/dto-group';

const initialState = new ProfilePageState();

const reducer = createReducer(initialState, (handleAction) => [
  handleAction(ProfilePageActions.resetState, () => initialState),
  handleAction(ProfilePageActions.edit, (state) => ({
    ...state,
    error: null,
    isEditing: true
  })),
  handleAction(ProfilePageActions.editSuccess, (state) => ({
    ...state,
    isEditing: false
  })),
  handleAction(ProfilePageActions.editFailure, (state, { payload }) => ({
    ...state,
    error: payload.error,
    isEditing: false
  })),
  handleAction(ProfilePageActions.changePassword, (state) => ({
    ...state,
    error: null,
    isEditing: true
  })),
  handleAction(ProfilePageActions.changePasswordSuccess, (state) => ({
    ...state,
    isEditing: false
  })),
  handleAction(ProfilePageActions.changePasswordFailure, (state, { payload }) => ({
    ...state,
    error: classToPlain(payload.error, { groups: [DTOGroup.MAIN], strategy: 'excludeAll' }),
    isEditing: false
  }))
]);

export function profilePageReducer(state: ProfilePageState | undefined, action: Action<any>): ProfilePageState {
  return reducer(state, action);
}
