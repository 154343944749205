import { createSelector } from 'reselect';
import { AppState } from 'src/store/state';
import { EnterNewPasswordPageState } from './state';
import { plainToClassFromExist } from 'class-transformer';
import { ServerError } from '../../../entities/server-errors';
import { RestorePasswordData } from '../../../entities/user';
import { DTOGroup } from '../../../enums/dto-group';

const selectFeature = (state: AppState): EnterNewPasswordPageState => state.pages.enterNewPassword;

export class EnterNewPasswordPageSelectors {
  public static isSending = createSelector(
    selectFeature,
    (state) => state.isSending
  );

  public static error = createSelector(
    selectFeature,
    (state) => plainToClassFromExist(
      new ServerError<RestorePasswordData>(RestorePasswordData),
      state.error,
      {
        groups: [DTOGroup.MAIN],
        strategy: 'excludeAll'
      }
    ) as ServerError<RestorePasswordData>
  );
}
