export class NavigationState {
  public isFooterMenuLoading: boolean;
  public footerMenu: Array<object>;
  public isLeftMenuLoading: boolean;
  public leftMenu: Array<object>;
  public isHomeMenuLoading: boolean;
  public homeMenu: Array<object>;

  constructor() {
    this.isFooterMenuLoading = false;
    this.footerMenu = [];
    this.isLeftMenuLoading = false;
    this.leftMenu = [];
    this.isHomeMenuLoading = false;
    this.homeMenu = [];
  }
}
