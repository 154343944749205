import { plainToClass } from 'class-transformer';
import { createSelector } from 'reselect';
import { Navigation } from 'src/entities/navigation';
import { DTOGroup } from 'src/enums/dto-group';
import { AppState } from 'src/store/state';
import { NavigationState } from './state';

const selectFeature = (state: AppState): NavigationState => state.navigation;

export class NavigationSelectors {
  public static isFooterMenuLoading = createSelector(
    selectFeature,
    (state) => state.isFooterMenuLoading
  );

  public static footerMenu = createSelector(
    selectFeature,
    (state) => plainToClass(
      Navigation,
      state.footerMenu,
      { groups: [DTOGroup.MAIN], strategy: 'excludeAll' }
    )
  );

  public static isLeftMenuLoading = createSelector(
    selectFeature,
    (state) => state.isLeftMenuLoading
  );

  public static leftMenu = createSelector(
    selectFeature,
    (state) => plainToClass(
      Navigation,
      state.leftMenu,
      { groups: [DTOGroup.MAIN], strategy: 'excludeAll' }
    )
  );

  public static isHomeMenuLoading = createSelector(
    selectFeature,
    (state) => state.isHomeMenuLoading
  );

  public static homeMenu = createSelector(
    selectFeature,
    (state) => plainToClass(
      Navigation,
      state.homeMenu,
      { groups: [DTOGroup.MAIN], strategy: 'excludeAll' }
    )
  );
}
