import { Exclude, Expose, Type } from 'class-transformer';
import { startCase } from 'lodash';
import { DTOGroup } from 'src/enums/dto-group';
import { BaseEntity } from '../base-entity';
import { State } from '../state';

export class County extends BaseEntity {
  @Expose({ groups: [DTOGroup.MAIN] })
  public name: string;

  @Expose({ name: 'state_id', groups: [DTOGroup.MAIN] })
  public stateID: number;

  @Type(() => State)
  @Expose({ groups: [DTOGroup.MAIN] })
  public state: State;

  @Expose({ name: 'listings_count', groups: [DTOGroup.MAIN] })
  public listingsCount: number;

  @Exclude()
  public get nameInKebabCase(): string {
    const [_, countyName] = this.name.split('- ');

    return `${countyName.toLowerCase()}-county-mineral-rights-sale`;
  }

  @Exclude()
  public get nameInCapitalizeCase(): string {
    return startCase(this.name)
      .trim();
  }

  @Exclude()
  public get nameWithoutPrefix(): string {
    return this.name.split('-')[1].trim();
  }

  constructor(model: Partial<County> = {}) {
    super(model);

    Object.assign(this, model);
  }
}
