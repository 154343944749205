export class SignAccessAgreementPageState {
  public isStatesLoading: boolean;
  public states: Array<object>;
  public isSending: boolean;

  constructor() {
    this.isStatesLoading = false;
    this.states = [];
    this.isSending = false;
  }
}
