import { Expose } from 'class-transformer';
import { DTOGroup } from 'src/enums/dto-group';

export class RegisterData {
  @Expose({ name: 'first_name', groups: [DTOGroup.CREATE] })
  public firstName: string;

  @Expose({ name: 'last_name', groups: [DTOGroup.CREATE] })
  public lastName: string;

  @Expose({ groups: [DTOGroup.CREATE] })
  public username: string;

  @Expose({ groups: [DTOGroup.CREATE] })
  public email: string;

  @Expose({ groups: [DTOGroup.CREATE] })
  public password: string;

  @Expose({ groups: [DTOGroup.CREATE] })
  public confirm: string;

  @Expose({ groups: [DTOGroup.CREATE] })
  public company?: string;

  @Expose({ groups: [DTOGroup.CREATE] })
  public city: string;

  @Expose({ groups: [DTOGroup.CREATE] })
  public state: string;

  @Expose({ name: 'zip_code', groups: [DTOGroup.CREATE] })
  public zipCode: string;

  @Expose({ groups: [DTOGroup.CREATE] })
  public phone: string;

  @Expose({ groups: [DTOGroup.CREATE] })
  public address: string;

  @Expose({ name: 'captcha_token', groups: [DTOGroup.CREATE] })
  public reCaptchaToken: string;

  constructor(model: Partial<RegisterData> = {}) {
    Object.assign(this, model);
  }
}
