import classNames from 'classnames/bind';
import Icon, { IconVariant } from 'src/components/ui-kit/icon/icon';
import Link, { LinkProps } from 'next/link';
import styles from './layout.module.scss';

const classesContext = classNames.bind(styles);

export default function LayoutHeaderLink({
  className,
  iconVariant,
  children,
  ...restProps
}: { className?: string; iconVariant: IconVariant } & React.PropsWithChildren<LinkProps>): JSX.Element {
  return (
    <Link {...restProps}>
      <a className={classesContext('link', 'header_link', className)}>
        <Icon variant={iconVariant} />
        {children}
      </a>
    </Link>
  );
}
