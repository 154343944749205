import { createSelector } from 'reselect';
import { AppState } from 'src/store/state';
import { MineralListingsPageState } from './state';
import { Listing } from 'src/entities/listing';
import { State } from 'src/entities/state';
import { plainToClass } from 'class-transformer';
import { DTOGroup } from '../../../enums/dto-group';

const selectFeature = (state: AppState): MineralListingsPageState => state.pages.mineralListings;

export class MineralListingsPageSelectors {
  public static isLoading = createSelector(
    selectFeature,
    (state) => state.isLoading
  );

  public static isStatesLoading = createSelector(
    selectFeature,
    (state) => state.isStatesLoading
  );

  public static items = createSelector(
    selectFeature,
    (state) => plainToClass(
      Listing,
      state.items,
      { groups: [DTOGroup.MAIN], strategy: 'excludeAll' }
    )
  );

  public static total = createSelector(
    selectFeature,
    (state) => state.total
  );

  public static perPage = createSelector(
    selectFeature,
    (state) => state.perPage
  );

  public static currentPage = createSelector(
    selectFeature,
    (state) => state.currentPage
  );

  public static states = createSelector(
    selectFeature,
    (state) => plainToClass(
      State,
      state.states,
      { groups: [DTOGroup.MAIN], strategy: 'excludeAll' }
    )
  );
}
