import { PaginationResponse } from 'src/entities/pagination';
import { ServerError } from 'src/entities/server-errors';
import { SignAgreementData } from 'src/entities/sign-agreement';
import { State } from 'src/entities/state';
import { action, actionWithPayload } from 'src/store/action-factory';

export class SignAccessAgreementPageActions {
  public static resetState = action(
    '[SignAccessAgreement] Reset state'
  );

  public static loadStates = action(
    '[SignAccessAgreement] Load States'
  );

  public static loadStatesForServer = action(
    '[SignAccessAgreement] Load States for Server'
  );

  public static loadStatesSuccess = actionWithPayload<{ response: PaginationResponse<State> }>(
    '[SignAccessAgreement] Load States Success'
  );

  public static loadStatesFailure = actionWithPayload<{ error: ServerError<any> }>(
    '[SignAccessAgreement] Load States Failure'
  );

  public static signAgreement = actionWithPayload<{ agreement: SignAgreementData }>(
    '[SignAccessAgreement] Sign Agreement'
  );

  public static signAgreementSuccess = actionWithPayload<{ response: SignAgreementData }>(
    '[SignAccessAgreement] Sign Agreement Success'
  );

  public static signAgreementFailure = actionWithPayload<{ error: ServerError<SignAgreementData> | null }>(
    '[SignAccessAgreement] Sign Agreement Failure'
  );
}
