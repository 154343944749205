import { classToPlain, plainToClass, plainToClassFromExist } from 'class-transformer';
import { DTOGroup } from 'src/enums/dto-group';
import { apiService } from './api';
import { ServerError } from 'src/entities/server-errors';
import { SellerPortalData } from 'src/entities/seller-portal';

export class SellerPortalService {
  public async create(sale: SellerPortalData): Promise<SellerPortalData> {
    const data = classToPlain<SellerPortalData>(sale, { groups: [DTOGroup.CREATE], strategy: 'excludeAll' });

    try {
      const response = await apiService.post<SellerPortalData>('/seller-portal-items', data);

      return plainToClass(SellerPortalData, response, { groups: [DTOGroup.CREATE] });
    } catch (error) {
      throw plainToClassFromExist(new ServerError<SellerPortalData>(SellerPortalData), error, {
        groups: [DTOGroup.CREATE],
        strategy: 'excludeAll'
      });
    }
  }
}

export const sellerPortalService = new SellerPortalService();
