import '../styles/globals.scss';
import { appWithTranslation } from 'next-i18next';
import type { AppContext, AppInitialProps, AppProps } from 'next/app';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import 'reflect-metadata';
import { AppActions } from 'src/store/actions';
import { wrapper } from 'src/store/store';
import { appWithLayout } from '../hoc/app-with-layout';
import { Store } from 'redux';
import { AppState } from '../store/state';
import { loadFooterMenuTask, loadLeftMenuTask, NavigationActions } from '../store/navigation';
import App from 'next/app';
import NextNprogress from 'nextjs-progressbar';
import { toast } from 'react-toastify';
import { parseCookies } from 'nookies';
import { configuration } from '../configurations/configuration';
import { ServerErrorMessageActions } from 'src/store/server-error-message';
import { deserialize } from 'class-transformer';
import { isArray, mapValues } from 'lodash';
import { ServerError } from 'src/entities/server-errors';
import 'swiper/scss';
import 'swiper/scss/pagination';
import Head from 'next/head';
import { isIOS } from 'react-device-detect';
import { ReCaptchaProvider } from 'next-recaptcha-v3';

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  const dispatch = useDispatch();

  const cookies = parseCookies();
  const serverErrorMessagesFromCookies = cookies[configuration.cookies.keys.serverErrorMessage];

  useEffect(() => {
    if (process.browser) {
      dispatch(AppActions.init());
    }
  }, []);

  useEffect(() => {
    if (serverErrorMessagesFromCookies) {
      const serverErrorMessages = deserialize(ServerError, serverErrorMessagesFromCookies);

      if (serverErrorMessages) {
        mapValues(serverErrorMessages?.errors, (error) => {
          if (isArray(error) && error[0]) {
            toast.error(error[0]);
          }
        });
        dispatch(ServerErrorMessageActions.removeMessage());
      }
    }
  }, []);

  return (
    <>
      <Head>
        <meta
          name='viewport'
          content={`width=device-width, initial-scale=1${isIOS ? ', maximum-scale=1' : ''}`} />
      </Head>
      <NextNprogress
        color='#3881C4'
        height={2} />
      <ReCaptchaProvider
        useEnterprise
        reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}>
        <Component {...pageProps} />
      </ReCaptchaProvider>
    </>
  );
}

MyApp.getInitialProps = wrapper.getInitialAppProps(
  (store: Store<AppState>) => async (appContext: AppContext): Promise<AppInitialProps> => {
    const appProps = await App.getInitialProps(appContext);
    const state = store.getState();

    if (!state.navigation.footerMenu.length) {
      store.dispatch(NavigationActions.loadFooterMenuForServer());

      await loadFooterMenuTask?.toPromise();
    }

    if (!state.navigation.leftMenu.length) {
      store.dispatch(NavigationActions.loadLeftMenuForServer());

      await loadLeftMenuTask?.toPromise();
    }

    return { ...appProps };
  }
);

export default wrapper.withRedux(appWithTranslation(appWithLayout(MyApp)));
