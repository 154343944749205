import { ListingInquiryPageActions } from './actions';
import { Action } from 'deox';
import { SagaIterator } from 'redux-saga';
import { takeLatest, call, put } from 'typed-redux-saga';
import { listingInquiryService } from 'src/services/listing-inquiry';
import { toast } from 'react-toastify';
import { i18n as i18next } from 'next-i18next';
import { i18n } from 'next-i18next.config';

function* create({ payload }: Action<string, typeof ListingInquiryPageActions.create.payload>): SagaIterator {
  try {
    yield* call(listingInquiryService.create, payload.listingInquiry);

    yield* put(ListingInquiryPageActions.createSuccess());

    const t = i18next?.getFixedT(i18n.defaultLocale, 'components:pages:listings:inquiry:form');
    if (t) {
      toast.success(t('TEXT_MESSAGE_SUCCESS') as string);
    }
  } catch (error) {
    yield* put(ListingInquiryPageActions.createFailure({ error }));
  }
}

function* createFailure(): SagaIterator {
  const t = i18next?.getFixedT(i18n.defaultLocale, 'components:pages:listings:inquiry:form');

  if (t) {
    yield* call(toast.error, t('ERRORS.TEXT_SENDING_ERROR'));
  }
}

export default function* listingInquiryPageSaga(): SagaIterator {
  yield* takeLatest(ListingInquiryPageActions.create, create);
  yield* takeLatest(ListingInquiryPageActions.createFailure, createFailure);
}
