import { createSelector } from 'reselect';
import { AppState } from 'src/store/state';
import { ProfileState } from './state';
import { plainToClass, plainToClassFromExist } from 'class-transformer';
import { User } from '../../entities/user';
import { DTOGroup } from '../../enums/dto-group';
import { ServerError } from 'src/entities/server-errors';
import { SignAgreementResponse } from 'src/entities/user/sign-agreement-response';

const selectFeature = (state: AppState): ProfileState => state.profile;

export class ProfileSelectors {
  public static profile = createSelector(
    selectFeature,
    (state) => plainToClass(
      User,
      state.profile,
      { groups: [DTOGroup.MAIN], strategy: 'excludeAll' }
    )
  );

  public static isRefreshing = createSelector(
    selectFeature,
    (state) => state.isRefreshing
  );

  public static isAgreementSigned = createSelector(
    selectFeature,
    (state) => state.isAgreementSigned
  );

  public static error = createSelector(
    selectFeature,
    (state) => plainToClassFromExist(
      new ServerError<SignAgreementResponse>(SignAgreementResponse),
      state.error,
      {
        groups: [DTOGroup.MAIN],
        strategy: 'excludeAll'
      }
    ) as ServerError<SignAgreementResponse>
  );
}
