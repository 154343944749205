export const configuration = {
  pagination: {
    allowedPerPage: [10, 25, 50, 100],
    defaultPerPage: 10
  },
  auth: {
    unauthorizedRoutes: ['/login', '/register'],
    logoutRoute: '/auth/logout',
    logoutRedirect: '/'
  },
  youtube: {
    url: 'https://www.youtube.com/embed/WJB7BP0yhr8'
  },
  cookies: {
    keys: {
      isAuthenticated: 'is_authenticated',
      userName: 'user_name',
      userId: 'user_id',
      serverErrorMessage: 'server_error_message'
    }
  },
  revalidation: {
    timeSec: 60 * 60 * 24
  },
  redirectPaths: {
    checkPreview: '/api/check-preview',
    signAccessAgreement: '/buyer/sign_legal_document',
    login: '/users/login'
  }
};
