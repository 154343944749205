import { ContactSupportData } from 'src/entities/contact-support';
import { ServerError } from 'src/entities/server-errors';
import { ContactSupportIssueForm } from 'src/forms/contact-support-issue';
import { action, actionWithPayload } from 'src/store/action-factory';

export class ContactSupportPageActions {
  public static resetState = action(
    '[ContactSupportPage] Reset state'
  );

  public static sendQuestion = actionWithPayload<{ question: ContactSupportData }>(
    '[ContactSupportPage] Send Question'
  );

  public static sendQuestionSuccess = actionWithPayload<{ response: ContactSupportIssueForm }>(
    '[ContactSupportPage] Send Question Success'
  );

  public static sendQuestionFailure = actionWithPayload<{ error: ServerError<ContactSupportData> | null }>(
    '[ContactSupportPage] Send Question Failure'
  );
}
