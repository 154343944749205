import { Exclude, Expose, Type } from 'class-transformer';

export class PaginationResponse<T> {
  @Expose({ name: 'current_page', toClassOnly: true })
  public currentPage: number;

  @Expose({ name: 'per_page', toClassOnly: true })
  public perPage: number;

  @Expose({ name: 'last_page', toClassOnly: true })
  public lastPage: number;

  @Expose({ toClassOnly: true })
  public total: number;

  @Expose({ toClassOnly: true })
  @Type((options) => (options?.newObject as PaginationResponse<T>).type)
  public data: Array<T>;

  @Exclude()
  private type: Function;

  constructor(type: Function) {
    this.type = type;
  }
}
