import ButtonIcon from 'src/components/ui-kit/button/__icon';
import FormTextInput from 'src/components/ui-kit/form/_text-input';
import Icon, { IconTheme, IconVariant } from 'src/components/ui-kit/icon/icon';
import { ChangeEvent, DetailedHTMLProps, InputHTMLAttributes, useEffect, useState } from 'react';
import styles from './layout.module.scss';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useRouterListings } from '../../hooks/use-router-listings';
import { useMediaQuery } from '@react-hooks-library/core';

export default function LayoutSearch({
  placeholder,
  ...inputProps
}: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>): JSX.Element {
  const { t } = useTranslation(['components:layout']);

  const router = useRouter();
  const routerListings = useRouterListings();
  const currentQueryParams = routerListings.getQueryParams();
  const isTablet = useMediaQuery('(max-width: 1024px)');

  const [query, setQuery] = useState<string>(currentQueryParams?.query || '');

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;

    setQuery(value);
  };

  const handleSearch = (): void => {
    if (currentQueryParams.query !== query) {
      routerListings.push({
        page: null,
        orderBy: null,
        orderByDirection: null,
        filters: {
          isCashFlow: null,
          statesIDs: null,
          countiesIDs: null,
          listings: null,
          query
        }
      });
    }
  };

  const handlePress = (event: React.KeyboardEvent): void => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      handleSearch();
    }
  };

  useEffect(() => {
    if (currentQueryParams.query !== query) {
      setQuery(currentQueryParams.query || '');
    }
  }, [router.query]);

  return (
    <div className={styles.search}>
      <div className={styles.search_input}>
        <FormTextInput
          placeholder={placeholder}
          type='text'
          value={query}
          onKeyDown={handlePress}
          onChange={handleChange}
          isBordered={isTablet}
          {...inputProps}
        />
      </div>
      <div className={styles.search_action}>
        <ButtonIcon
          className={styles.search_action_button}
          onClick={handleSearch}
          type='button'
          aria-label={t('SEARCH.BUTTON_LABEL')}
        >
          <Icon
            variant={IconVariant.SEARCH}
            theme={IconTheme.WHITE} />
          <span className={styles.search_action_text}>{t('SEARCH.BUTTON_LABEL')}</span>
        </ButtonIcon>
      </div>
    </div>
  );
}
