import { UploadFilesPageActions } from './actions';
import { Action } from 'deox';
import { END, EventChannel, SagaIterator } from 'redux-saga';
import { call, put, fork, take, takeEvery } from 'typed-redux-saga';
import { toast } from 'react-toastify';
import { i18n as i18next } from 'next-i18next';
import { i18n } from 'next-i18next.config';
import { createUploader } from 'src/utils/create-upload';
import { uploadedFilesService } from 'src/services/uploaded-files';
import { UploadedFilesData } from 'src/entities/uploaded-files/uploaded-files';

function* uploadProgressWatcher(channelUploadFile: EventChannel<number | END>, file: File): SagaIterator {
  while (true) {
    const progress = yield* take(channelUploadFile);
    yield* put(UploadFilesPageActions.uploadFileProgress({ file, progress }));
  }
}

function* uploadFile({ payload }: Action<string, typeof UploadFilesPageActions.uploadFile.payload>): SagaIterator {
  try {
    const [uploadPromise, channelUploadFile] = yield* call(createUploader, payload.file);

    yield* call(function* () {
      yield* fork(uploadProgressWatcher, channelUploadFile, payload.file);
    });

    const file = yield* call(() => uploadPromise);

    yield* put(UploadFilesPageActions.uploadFileSuccess({ media: file, file: payload.file }));

    yield* call(uploadedFilesService.uploadFile, new UploadedFilesData({ mediaID: file.id }));
  } catch (error) {
    const t = i18next?.getFixedT(i18n.defaultLocale, 'components:pages:upload-files');

    if (t) {
      yield* call(toast.error, t('ERRORS.TEXT_ERROR_SENDING_THE_FILE', { name: payload.file.name }));
    }

    yield* put(UploadFilesPageActions.uploadFileFailure({ file: payload.file }));
  }
}

export default function* uploadFilesPageSaga(): SagaIterator {
  yield* takeEvery(UploadFilesPageActions.uploadFile, uploadFile);
}
