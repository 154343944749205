import { plainToClass, plainToClassFromExist } from 'class-transformer';
import { DTOGroup } from 'src/enums/dto-group';
import { apiService } from './api';
import { ServerError } from 'src/entities/server-errors';
import { ContactSupportData } from 'src/entities/contact-support';

export class ContactSupportService {
  public async sendQuestion(question: ContactSupportData): Promise<ContactSupportData> {
    try {
      const response = await apiService.post<ContactSupportData>('/contact-support', question);

      return plainToClass(ContactSupportData, response, { groups: [DTOGroup.MAIN], strategy: 'excludeAll' });
    } catch (error) {
      throw plainToClassFromExist(new ServerError<ContactSupportData>(ContactSupportData), error, {
        groups: [DTOGroup.CREATE],
        strategy: 'excludeAll'
      });
    }
  }
}

export const contactSupportService = new ContactSupportService();
