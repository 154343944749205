import { Action } from 'deox';
import { ContactSupportPageActions } from './actions';
import { SagaIterator } from 'redux-saga';
import { takeLatest, call, put } from 'typed-redux-saga';
import { contactSupportService } from 'src/services/contact-support';
import { toast } from 'react-toastify';
import { i18n as i18next } from 'next-i18next';
import { i18n } from 'next-i18next.config';

function* sendingQuestion({
  payload
}: Action<string, typeof ContactSupportPageActions.sendQuestion.payload>): SagaIterator {
  try {
    const response = yield* call(contactSupportService.sendQuestion, payload.question);

    yield* put(ContactSupportPageActions.sendQuestionSuccess({ response }));

    const t = i18next?.getFixedT(i18n.defaultLocale, 'components:pages:contact-support');
    if (t) {
      toast.success(t('TEXT_MESSAGE_SUCCESS') as string);
    }
  } catch (error) {
    yield* put(ContactSupportPageActions.sendQuestionFailure({ error }));
  }
}

function* sendQuestionFailure(): SagaIterator {
  const t = i18next?.getFixedT(i18n.defaultLocale, 'components:pages:contact-support');

  if (t) {
    yield* call(toast.error, t('ERRORS.TEXT_SENDING_ERROR'));
  }
}

export default function* contactSupportPageSaga(): SagaIterator {
  yield* takeLatest(ContactSupportPageActions.sendQuestion, sendingQuestion);
  yield* takeLatest(ContactSupportPageActions.sendQuestionFailure, sendQuestionFailure);
}
