import { Action, createReducer } from 'deox';
import { ContactSupportPageState } from './state';
import { ContactSupportPageActions } from './actions';
import { classToPlain } from 'class-transformer';
import { DTOGroup } from '../../../enums/dto-group';

const initialState = new ContactSupportPageState();

const reducer = createReducer(initialState, (handleAction) => [
  handleAction(ContactSupportPageActions.resetState, () => initialState),
  handleAction(ContactSupportPageActions.sendQuestion, (state) => ({
    ...state,
    error: null,
    isSending: true,
    isSubmitted: false
  })),
  handleAction(ContactSupportPageActions.sendQuestionSuccess, (state) => ({
    ...state,
    isSending: false,
    isSubmitted: true
  })),
  handleAction(ContactSupportPageActions.sendQuestionFailure, (state, { payload }) => ({
    ...state,
    error: classToPlain(payload.error, { groups: [DTOGroup.MAIN], strategy: 'excludeAll' }),
    isSending: false,
    isSubmitted: false
  }))
]);

export function contactSupportPageReducer(
  state: ContactSupportPageState | undefined,
  action: Action<any>
): ContactSupportPageState {
  return reducer(state, action);
}
