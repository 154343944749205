import { Expose } from 'class-transformer';
import { DTOGroup } from 'src/enums/dto-group';

export class SignAgreementResponse {
  @Expose({ name: 'is_agreement_signed', groups: [DTOGroup.MAIN] })
  public isAgreementSigned: boolean;

  constructor(model: Partial<SignAgreementResponse> = {}) {
    Object.assign(this, model);
  }
}
