import { Action, createReducer } from 'deox';
import { ListingViewPageActions } from './actions';
import { ListingViewPageState } from './state';
import { AppActions } from 'src/store/actions';
import { classToPlain } from 'class-transformer';
import { DTOGroup } from '../../../enums/dto-group';

const initialState = new ListingViewPageState();

const reducer = createReducer(initialState, (handleAction) => [
  handleAction(ListingViewPageActions.resetState, () => initialState),
  handleAction(AppActions.hydrate, (state, { payload }) => ({
    ...state,
    listing: payload.pages.listingView.listing
  })),
  handleAction(ListingViewPageActions.loadListingView, (state) => ({
    ...state,
    isLoading: true
  })),
  handleAction(ListingViewPageActions.loadListingViewSuccess, (state, { payload }) => ({
    ...state,
    listing: classToPlain(payload.response, { groups: [DTOGroup.MAIN], strategy: 'excludeAll' }),
    isLoading: false
  })),
  handleAction(ListingViewPageActions.loadListingViewFailure, (state, { payload }) => ({
    ...state,
    error: classToPlain(payload.error, { groups: [DTOGroup.MAIN], strategy: 'excludeAll' }),
    isLoading: false
  }))
]);

export function listingViewPageReducer(
  state: ListingViewPageState | undefined,
  action: Action<any>
): ListingViewPageState {
  return reducer(state, action);
}
