import { Expose, Transform } from 'class-transformer';
import { castArray } from 'lodash';
import { PaginationRequest } from 'src/entities/pagination';

export class CountyPaginationRequest extends PaginationRequest {
  @Transform(({ value }) => (value ? castArray(value) : undefined))
  @Expose({ name: 'states_ids', toPlainOnly: true })
  public statesIDs: Array<number>;

  constructor(model: Partial<CountyPaginationRequest> = {}) {
    super(model);

    Object.assign(this, model);
  }
}
