export class ListingViewPageState {
  public isLoading: boolean;
  public listing: object | null;
  public error: object | null;

  constructor() {
    this.isLoading = false;
    this.listing = null;
    this.error = null;
  }
}
