import { Action, createReducer } from 'deox';
import { AuthActions } from './actions';
import { AuthState } from './state';

const initialState = new AuthState();

const reducer = createReducer(initialState, (handleAction) => [
  handleAction(AuthActions.authorize, (state) => ({
    ...state,
    isAuthenticating: true
  })),
  handleAction(AuthActions.authorizeSuccess, (state) => ({
    ...state,
    isAuthenticating: false,
    isAuthenticated: true
  })),
  handleAction(AuthActions.authorizeFailure, (state) => ({
    ...state,
    isAuthenticating: false
  })),
  handleAction(AuthActions.unauthorize, (state) => ({
    ...state,
    isAuthenticated: false
  })),
  handleAction(AuthActions.setIsAuthenticated, (state, { payload }) => ({
    ...state,
    isAuthenticated: payload.isAuthenticated
  }))
]);

export function authReducer(state: AuthState | undefined, action: Action<any>): AuthState {
  return reducer(state, action);
}
