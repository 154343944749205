import authSaga from './auth/saga';
import profileSaga from './profile/saga';
import signUpPageSaga from './pages/sign-up/saga';
import restorePasswordPageSaga from './pages/restore-password/saga';
import profilePageSaga from './pages/profile/saga';
import { all, put, takeLatest } from 'typed-redux-saga';
import { configuration } from '../configurations/configuration';
import { AuthActions } from './auth';
import { ProfileActions } from './profile';
import { User } from '../entities/user';
import { SagaIterator } from 'redux-saga';
import { AppActions } from './actions';
import listingViewPageSaga from './pages/listing-view/saga';
import { parseCookies } from 'nookies';
import contactSupportPageSaga from './pages/contact-support/saga';
import listingInquiryPageSaga from './pages/listing-inquiry/saga';
import sellerPortalPageSaga from './pages/seller-portal/saga';
import featuredListingsSaga from './featured-listings/saga';
import navigationSaga from './navigation/saga';
import signAccessAgreementPageSaga from './pages/sign-access-agreement/saga';
import mineralListingsPageSaga from './pages/mineral-listings/saga';
import enterNewPasswordPageSaga from './pages/enter-new-password/saga';
import serverErrorMessageSaga from './server-error-message/saga';
import uploadFilesPageSaga from './pages/upload-files/saga';
import unsubscribePageSaga from './pages/unsubscribe/saga';

function* init(): SagaIterator {
  const cookies = parseCookies();

  const isAuthenticated = cookies[configuration.cookies.keys.isAuthenticated] === 'true';
  yield* put(AuthActions.setIsAuthenticated({ isAuthenticated }));

  if (isAuthenticated) {
    const userName = cookies[configuration.cookies.keys.userName] ?? '';
    if (userName) {
      yield* put(ProfileActions.upsertState({ profile: new User({ firstName: userName }) }));
    }

    yield* put(ProfileActions.refresh());
  }
}

export function* rootSaga(): Generator {
  yield* takeLatest(AppActions.init, init);

  yield* all([
    authSaga(),
    profileSaga(),
    signUpPageSaga(),
    restorePasswordPageSaga(),
    profilePageSaga(),
    mineralListingsPageSaga(),
    listingViewPageSaga(),
    listingInquiryPageSaga(),
    contactSupportPageSaga(),
    sellerPortalPageSaga(),
    featuredListingsSaga(),
    navigationSaga(),
    signAccessAgreementPageSaga(),
    enterNewPasswordPageSaga(),
    serverErrorMessageSaga(),
    uploadFilesPageSaga(),
    unsubscribePageSaga()
  ]);
}
