import classNames from 'classnames/bind';
import Link from 'next/link';
import styles from './layout.module.scss';

const classesContext = classNames.bind(styles);

export default function LayoutFooterLink({ label, link }: { label: string; link: string }): JSX.Element {
  return (
    <Link href={link}>
      <a className={classesContext(['link', 'footer_item'])}>{label}</a>
    </Link>
  );
}
