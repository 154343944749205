import { Expose, Transform } from 'class-transformer';
import { ListingRelation, ListingOrderBy } from 'src/enums/listing';
import { PaginationRequest } from 'src/entities/pagination';
import { TransformBoolean } from 'src/utils/transform-boolean';
import { castArray } from 'lodash';
import { ListingFilterKeys } from 'src/enums/listing/filter-keys';

export class ListingPaginationRequest extends PaginationRequest<ListingRelation, ListingOrderBy> {
  @Transform(({ value }) => (value ? castArray(value) : undefined))
  @Expose({ name: 'states_ids', toPlainOnly: true })
  public statesIDs: Array<number>;

  @Transform(({ value }) => (value ? castArray(value) : undefined))
  @Expose({ name: 'counties_ids', toPlainOnly: true })
  public countiesIDs: Array<number>;

  @TransformBoolean()
  @Expose({ name: 'cash_flow_only', toPlainOnly: true })
  public isCashFlow: boolean;

  @Expose({ toPlainOnly: true })
  public listings: ListingFilterKeys;

  constructor(model: Partial<ListingPaginationRequest> = {}) {
    super(model);

    Object.assign(this, model);
  }
}
