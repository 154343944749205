import { createSelector } from 'reselect';
import { AppState } from 'src/store/state';
import { plainToClassFromExist } from 'class-transformer';
import { ServerError } from '../../../entities/server-errors';
import { DTOGroup } from '../../../enums/dto-group';
import { SellerPortalData } from 'src/entities/seller-portal';
import { SellerPortalPageState } from './state';

const selectFeature = (state: AppState): SellerPortalPageState => state.pages.sellerPortal;

export class SellerPortalPageSelectors {
  public static isSending = createSelector(
    selectFeature,
    (state) => state.isSending
  );

  public static isSubmitted = createSelector(
    selectFeature,
    (state) => state.isSubmitted
  );

  public static files = createSelector(
    selectFeature,
    (state) => state.files
  );

  public static error = createSelector(
    selectFeature,
    (state) => plainToClassFromExist(
      new ServerError<SellerPortalData>(SellerPortalData),
      state.error,
      {
        groups: [DTOGroup.MAIN],
        strategy: 'excludeAll'
      }
    ) as ServerError<SellerPortalData>
  );
}
