import { Exclude, Expose, Type } from 'class-transformer';
import { kebabCase, toUpper } from 'lodash';
import { DateTime } from 'luxon';
import { DTOGroup } from 'src/enums/dto-group';
import { ListingBanner, ListingSource, ListingStatus, ListingType } from 'src/enums/listing';
import { TransformDate } from 'src/utils/transform-date';
import { BaseEntity } from '../base-entity';
import { County } from '../county';
import { Media } from '../media';
import { State } from '../state';
import { User } from '../user';

export class Listing extends BaseEntity {
  @Expose({ name: 'user_id', groups: [DTOGroup.MAIN] })
  public userID: number;

  @Type(() => User)
  @Expose({ groups: [DTOGroup.MAIN] })
  public user: User;

  @Expose({ groups: [DTOGroup.MAIN] })
  public title: string;

  @Expose({ name: 'state_id', groups: [DTOGroup.MAIN] })
  public stateID: number;

  @Type(() => State)
  @Expose({ groups: [DTOGroup.MAIN] })
  public state: State;

  @Expose({ name: 'county_id', groups: [DTOGroup.MAIN] })
  public countyID: number;

  @Type(() => County)
  @Expose({ groups: [DTOGroup.MAIN] })
  public county: County;

  @Expose({ name: 'net_acres', groups: [DTOGroup.MAIN] })
  public netAcres: number;

  @Expose({ name: 'legal_location', groups: [DTOGroup.MAIN] })
  public legalLocation: string;

  @Expose({ name: 'is_active_lease', groups: [DTOGroup.MAIN] })
  public isActiveLease: boolean;

  @Expose({ name: 'lease_terms', groups: [DTOGroup.MAIN] })
  public leaseTerms: string;

  @Expose({ name: 'lease_bonus', groups: [DTOGroup.MAIN] })
  public leaseBonus: string;

  @Expose({ name: 'royalty_percentage', groups: [DTOGroup.MAIN] })
  public royaltyPercentage: number;

  @Expose({ name: 'is_held_by_production', groups: [DTOGroup.MAIN] })
  public isHeldByProduction: boolean;

  @Expose({ name: 'average_monthly_cash_flow', groups: [DTOGroup.MAIN] })
  public averageMonthlyCashFlow: number;

  @Expose({ groups: [DTOGroup.MAIN] })
  public operator: string;

  @Expose({ name: 'bid_basis', groups: [DTOGroup.MAIN] })
  public bidBasis: string;

  @Expose({ name: 'asking_price', groups: [DTOGroup.MAIN] })
  public askingPrice: string;

  @Expose({ name: 'starting_bid', groups: [DTOGroup.MAIN] })
  public startingBid: string;

  @Expose({ groups: [DTOGroup.MAIN] })
  public comment: string;

  @Expose({ groups: [DTOGroup.MAIN] })
  public status: ListingStatus;

  @Expose({ name: 'is_featured', groups: [DTOGroup.MAIN] })
  public isFeatured: boolean;

  @Expose({ name: 'is_bold', groups: [DTOGroup.MAIN] })
  public isBold: boolean;

  @Expose({ groups: [DTOGroup.MAIN] })
  public notes: string;

  @Expose({ groups: [DTOGroup.MAIN] })
  public banner: ListingBanner;

  @Expose({ groups: [DTOGroup.MAIN] })
  public source: ListingSource;

  @Expose({ groups: [DTOGroup.MAIN] })
  public type: ListingType;

  @Expose({ groups: [DTOGroup.MAIN] })
  public url: string;

  @Expose({ name: 'is_index', groups: [DTOGroup.MAIN] })
  public isIndex: boolean;

  @Expose({ name: 'expired_at', groups: [DTOGroup.MAIN] })
  @Type(() => DateTime)
  @TransformDate()
  public expiredAt: DateTime;

  @Expose({ name: 'activated_at', groups: [DTOGroup.MAIN] })
  @Type(() => DateTime)
  @TransformDate()
  public activatedAt: DateTime;

  @Expose({ name: 'media_maps', groups: [DTOGroup.MAIN] })
  @Type(() => Media)
  public mediaMaps: Array<Media>;

  @Expose({ name: 'media_documents', groups: [DTOGroup.MAIN] })
  @Type(() => Media)
  public mediaDocuments: Array<Media>;

  @Exclude()
  public get typeInKebabCase(): string {
    return kebabCase(this.type);
  }

  @Exclude()
  public get bannerInUpperCase(): string {
    return toUpper(this.banner);
  }

  @Exclude()
  public get archive(): string {
    return `${process.env.NEXT_PUBLIC_API_URL}/listings/${this.id}/documents`;
  }

  @Exclude()
  public get roundedAverageMonthlyCashFlow(): number {
    return Math.round(this.averageMonthlyCashFlow);
  }

  @Exclude()
  public get roundedNetAcres(): number {
    return +Number(this.netAcres)
      .toFixed(1);
  }

  @Exclude()
  public get formatUsAverageMonthlyCashFlow(): string {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    })
      .format(this.averageMonthlyCashFlow);
  }

  constructor(model: Partial<Listing> = {}) {
    super(model);

    Object.assign(this, model);
  }
}
