import { Action, createReducer } from 'deox';
import { ListingInquiryPageActions } from './actions';
import { ListingInquiryPageState } from './state';
import { classToPlain } from 'class-transformer';
import { DTOGroup } from '../../../enums/dto-group';

const initialState = new ListingInquiryPageState();

const reducer = createReducer(initialState, (handleAction) => [
  handleAction(ListingInquiryPageActions.resetState, () => initialState),
  handleAction(ListingInquiryPageActions.create, (state) => ({
    ...state,
    error: null,
    isSending: true
  })),
  handleAction(ListingInquiryPageActions.createSuccess, (state) => ({
    ...state,
    isSubmitted: true,
    isSending: false
  })),
  handleAction(ListingInquiryPageActions.createFailure, (state, { payload }) => ({
    ...state,
    error: classToPlain(payload.error, { groups: [DTOGroup.MAIN], strategy: 'excludeAll' }),
    isSending: false,
    isSubmitted: false
  }))
]);

export function listingInquiryPageReducer(
  state: ListingInquiryPageState | undefined,
  action: Action<any>
): ListingInquiryPageState {
  return reducer(state, action);
}
