import { createSelector } from 'reselect';
import { AppState } from 'src/store/state';
import { ContactSupportPageState } from './state';
import { ServerError } from '../../../entities/server-errors';
import { ContactSupportData } from '../../../entities/contact-support';
import { DTOGroup } from '../../../enums/dto-group';
import { plainToClassFromExist } from 'class-transformer';

const selectFeature = (state: AppState): ContactSupportPageState => state.pages.contactSupport;

export class ContactSupportPageSelectors {
  public static isSending = createSelector(
    selectFeature,
    (state) => state.isSending
  );

  public static isSubmitted = createSelector(
    selectFeature,
    (state) => state.isSubmitted
  );

  public static error = createSelector(
    selectFeature,
    (state) => plainToClassFromExist(
      new ServerError<ContactSupportData>(ContactSupportData),
      state.error,
      {
        groups: [DTOGroup.MAIN],
        strategy: 'excludeAll'
      }
    ) as ServerError<ContactSupportData>
  );
}
