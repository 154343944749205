import classNames from 'classnames/bind';
import { AnchorHTMLAttributes, DetailedHTMLProps, forwardRef, Ref } from 'react';
import styles from './button.module.scss';

const classesContext = classNames.bind(styles);

function ButtonTab(
  {
    children,
    isActive,
    className,
    ...restProps
  }: { isActive: boolean } & DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
  ref: Ref<HTMLAnchorElement>
): JSX.Element {
  return (
    <a
      ref={ref}
      className={classesContext([
        'button',
        'button_primary',
        'button__tab',
        'tab',
        { tab__active: isActive },
        className
      ])}
      {...restProps}
    >
      {children}
    </a>
  );
}

export default forwardRef(ButtonTab);
