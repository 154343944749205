import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import { ApiCall } from '../types';
import { logoutInterceptor, unauthorizedInterceptor } from '../interceptors';

export class Api {
  public readonly post: ApiCall;
  public readonly get: ApiCall;
  public readonly patch: ApiCall;
  public readonly put: ApiCall;
  public readonly delete: ApiCall;

  private readonly httpClient: AxiosInstance;

  constructor(baseURL: string = process.env.NEXT_PUBLIC_API_URL as string, baseConfig?: AxiosRequestConfig) {
    const config: AxiosRequestConfig = {
      baseURL,
      ...baseConfig
    };

    this.httpClient = axios.create(config);

    this.httpClient.interceptors.request.use(logoutInterceptor);
    this.httpClient.interceptors.response.use(undefined, unauthorizedInterceptor);

    this.post = this.request('post');
    this.get = this.request('get');
    this.patch = this.request('patch');
    this.put = this.request('put');
    this.delete = this.request('delete');
  }

  public static isAxiosError<T>(error: AxiosError | any): error is AxiosError<T> {
    return error && error.isAxiosError;
  }

  private request(method: Method, httpClient: AxiosInstance = this.httpClient): ApiCall {
    function apiCall<T = any>(
      endpoint: string,
      data?: any,
      options?: AxiosRequestConfig & { fullResponse?: false }
    ): Promise<T>;
    function apiCall<T = any>(
      endpoint: string,
      data?: any,
      options?: AxiosRequestConfig & { fullResponse: true }
    ): Promise<AxiosResponse<T>>;
    async function apiCall<T = any>(
      endpoint: string,
      data?: any,
      options?: AxiosRequestConfig & { fullResponse?: boolean }
    ): Promise<AxiosResponse<T> | T> {
      const payload = ['get', 'delete'].includes(method) ? { params: data } : { data };

      try {
        const res = await httpClient.request({
          method,
          url: endpoint,
          withCredentials: true,
          ...options,
          ...payload
        });

        return options?.fullResponse ? res : res.data;
      } catch (error) {
        throw error.response.data;
      }
    }

    return apiCall;
  }
}

export const apiService = new Api();

export const apiServerService = new Api(process.env.NEXT_PUBLIC_API_URL as string, {
  headers: {
    'api-key': process.env.API_KEY ?? ''
  }
});
