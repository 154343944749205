import { classToPlain, plainToClass, plainToClassFromExist } from 'class-transformer';
import { isUndefined, omitBy } from 'lodash';
import { Listing, ListingFilters, ListingPaginationRequest } from 'src/entities/listing';
import { ListingRetrieveRequest } from 'src/entities/listing';
import { PaginationResponse } from 'src/entities/pagination';
import { DTOGroup } from 'src/enums/dto-group';
import { ListingOrderBy, ListingRelation } from 'src/enums/listing';
import { apiService } from './api';

const endpoint = '/listings';

export class ListingService {
  public async search({
    filters,
    ...params
  }: {
    page?: number;
    perPage?: number;
    filters?: ListingFilters;
    desc?: boolean;
    orderBy?: ListingOrderBy;
    all?: boolean;
    relations?: Array<ListingRelation>;
  } = {}): Promise<PaginationResponse<Listing>> {
    const request = new ListingPaginationRequest({ ...omitBy(filters, isUndefined), ...params });

    const response = await apiService.get<PaginationResponse<Listing>>(
      endpoint,
      omitBy(classToPlain<ListingPaginationRequest>(request), isUndefined)
    );

    return plainToClassFromExist(new PaginationResponse<Listing>(Listing), response, {
      groups: [DTOGroup.MAIN],
      strategy: 'excludeAll'
    });
  }

  public async getByID(id: number, params?: { relations?: Array<ListingRelation> }): Promise<Listing> {
    const response = await apiService.get<Listing>(
      `${endpoint}/${id}`,
      classToPlain<ListingRetrieveRequest>(new ListingRetrieveRequest(params))
    );

    return plainToClass(Listing, response, {
      groups: [DTOGroup.MAIN],
      strategy: 'excludeAll'
    });
  }
}

export const listingService = new ListingService();
