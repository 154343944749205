import { StatusCodes } from 'http-status-codes';
import { store } from 'src/store/store';
import { AuthActions, AuthSelectors } from '../store/auth';
import { authService } from '../services/auth';
import axios from 'axios';
import { configuration } from '../configurations/configuration';

export const unauthorizedInterceptor = async (error: any): Promise<never> => {
  if (
    error?.response?.status === StatusCodes.UNAUTHORIZED &&
    !configuration.auth.unauthorizedRoutes.includes(error?.response?.config?.url)
  ) {
    const state = store.getState();
    const isAuthenticated = AuthSelectors.isAuthenticated(state);

    if (isAuthenticated && error?.response?.data?.error === 'Token has expired') {
      try {
        await authService.refreshToken();

        return axios.request(error.config);
      } catch {
        store.dispatch(AuthActions.unauthorize());
      }
    }
  }

  return Promise.reject(error);
};
