import { Transform } from 'class-transformer';

export function TransformDesc(): Function {
  const toPlain = Transform(
    ({ value }): number | undefined => {
      if (value !== undefined) {
        return Number(value);
      }

      return undefined;
    },
    { toPlainOnly: true }
  );

  return (target: any, key: string): void => {
    toPlain(target, key);
  };
}
