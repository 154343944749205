import { Action } from 'deox';
import { authService } from 'src/services/auth';
import { ProfileActions } from 'src/store/profile/actions';
import { AuthActions } from './actions';
import { call, put, takeLatest } from 'typed-redux-saga';
import { SagaIterator } from 'redux-saga';
import { configuration } from '../../configurations/configuration';
import { destroyCookie, setCookie } from 'nookies';
import { toast } from 'react-toastify';
import { i18n as i18next } from 'next-i18next';
import { i18n } from 'next-i18next.config';
import { NavigationActions } from '../navigation';
import { UserRelation } from 'src/enums/user/relation';
import Router from 'next/router';
import { DateTime } from 'luxon';

function* authorize({ payload }: Action<string, typeof AuthActions.authorize.payload>): SagaIterator {
  try {
    const response = yield* call(authService.login, payload.user, [UserRelation.LEGAL_AGREEMENT]);

    yield* put(NavigationActions.loadLeftMenu());
    yield* put(AuthActions.authorizeSuccess({ response }));

    if (!response.user.legalAgreement) {
      yield* call(Router.push, '/api/preview');
    } else {
      yield* call(Router.push, '/mineral-listings');
    }
  } catch (error) {
    yield* put(AuthActions.authorizeFailure());
  }
}

function* authorizeSuccess({ payload }: Action<string, typeof AuthActions.authorizeSuccess.payload>): SagaIterator {
  setCookie(null, configuration.cookies.keys.isAuthenticated, 'true', {
    path: '/',
    expires: DateTime.now()
      .plus({ minute: payload.response.ttl + payload.response.refreshTtl })
      .toJSDate()
  });

  yield* put(ProfileActions.upsertState({ profile: payload.response.user }));
}

function* unauthorize(): SagaIterator {
  destroyCookie(null, configuration.cookies.keys.isAuthenticated, {
    path: '/'
  });

  yield* put(ProfileActions.clearState());
  yield* call([authService, authService.logout]);
  yield* put(NavigationActions.loadLeftMenu());

  yield* call(Router.push, '/api/disable-preview');
}

function* authorizeFailure(): SagaIterator {
  const t = i18next?.getFixedT(i18n.defaultLocale, 'components:layout');

  if (t) {
    yield* call(toast.error, t('LOGIN.ERRORS.TEXT_INVALID_USERNAME_OR_PASSWORD'));
  }
}

export default function* authSaga(): SagaIterator {
  yield* takeLatest(AuthActions.authorize, authorize);
  yield* takeLatest(AuthActions.authorizeSuccess, authorizeSuccess);
  yield* takeLatest(AuthActions.unauthorize, unauthorize);
  yield* takeLatest(AuthActions.authorizeFailure, authorizeFailure);
}
