import { AxiosRequestConfig } from 'axios';
import { authService } from '../services/auth';
import { configuration } from '../configurations/configuration';

export const logoutInterceptor = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  if (config.url !== configuration.auth.logoutRoute && authService.logoutPromise) {
    await authService.logoutPromise;
  }

  return config;
};
