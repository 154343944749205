import { Action, createReducer } from 'deox';
import { MineralListingsPageActions } from './actions';
import { MineralListingsPageState } from './state';
import { AppActions } from 'src/store/actions';
import { classToPlain } from 'class-transformer';
import { DTOGroup } from 'src/enums/dto-group';

const initialState = new MineralListingsPageState();

const reducer = createReducer(initialState, (handleAction) => [
  handleAction(MineralListingsPageActions.resetState, () => initialState),
  handleAction(AppActions.hydrate, (state, { payload }) => ({
    ...state,
    items: payload.pages.mineralListings.items,
    states: payload.pages.mineralListings.states,
    total: payload.pages.mineralListings.total,
    currentPage: payload.pages.mineralListings.currentPage,
    perPage: payload.pages.mineralListings.perPage
  })),
  handleAction(MineralListingsPageActions.loadListings, (state, { payload }) => ({
    ...state,
    currentPage: payload.page ?? state.currentPage,
    isLoading: true
  })),
  handleAction(MineralListingsPageActions.loadListingsSuccess, (state, { payload }) => ({
    ...state,
    items: classToPlain(payload.response.data, { groups: [DTOGroup.MAIN], strategy: 'excludeAll' }) as Array<
      Record<string, any>
    >,
    total: payload.response.total,
    currentPage: payload.response.currentPage,
    isLoading: false
  })),
  handleAction(MineralListingsPageActions.loadListingsFailure, (state) => ({
    ...state,
    isLoading: false
  })),
  handleAction(MineralListingsPageActions.loadStates, (state) => ({
    ...state,
    isStatesLoading: true
  })),
  handleAction(MineralListingsPageActions.loadStatesSuccess, (state, { payload }) => ({
    ...state,
    states: classToPlain(payload.response.data, { groups: [DTOGroup.MAIN], strategy: 'excludeAll' }) as Array<
      Record<string, any>
    >,
    isStatesLoading: false
  })),
  handleAction(MineralListingsPageActions.loadStatesFailure, (state) => ({
    ...state,
    isStatesLoading: false
  })),
  handleAction(MineralListingsPageActions.setPerPage, (state, { payload }) => ({
    ...state,
    perPage: payload.perPage
  }))
]);

export function mineralListingsPageReducer(
  state: MineralListingsPageState | undefined,
  action: Action<any>
): MineralListingsPageState {
  return reducer(state, action);
}
