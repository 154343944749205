import { SellerPortalPageActions } from './actions';
import { Action } from 'deox';
import { END, EventChannel, SagaIterator } from 'redux-saga';
import { takeLatest, call, put, fork, take, takeEvery } from 'typed-redux-saga';
import { sellerPortalService } from 'src/services/seller-portal';
import { SellerPortalData } from 'src/entities/seller-portal';
import { toast } from 'react-toastify';
import { i18n as i18next } from 'next-i18next';
import { i18n } from 'next-i18next.config';
import { createUploader } from 'src/utils/create-upload';

function* create({ payload }: Action<string, typeof SellerPortalPageActions.create.payload>): SagaIterator {
  try {
    yield* call(sellerPortalService.create, new SellerPortalData(payload.data));

    yield* put(SellerPortalPageActions.createSuccess());

    const t = i18next?.getFixedT(i18n.defaultLocale, 'components:pages:seller-portal');
    if (t) {
      toast.success(t('TEXT_MESSAGE_SUCCESS') as string);
    }
  } catch (error) {
    yield* put(SellerPortalPageActions.createFailure({ error }));
  }
}

function* createFailure(): SagaIterator {
  const t = i18next?.getFixedT(i18n.defaultLocale, 'components:pages:seller-portal');

  if (t) {
    yield* call(toast.error, t('ERRORS.TEXT_SENDING_ERROR'));
  }
}

function* uploadProgressWatcher(channelUploadFile: EventChannel<number | END>, file: File): SagaIterator {
  while (true) {
    const progress = yield* take(channelUploadFile);
    yield* put(SellerPortalPageActions.uploadFileProgress({ file, progress }));
  }
}

function* uploadFile({ payload }: Action<string, typeof SellerPortalPageActions.uploadFile.payload>): SagaIterator {
  try {
    const [uploadPromise, channelUploadFile] = yield* call(createUploader, payload.file);

    yield* call(function* () {
      yield* fork(uploadProgressWatcher, channelUploadFile, payload.file);
    });

    const file = yield* call(() => uploadPromise);

    yield* put(SellerPortalPageActions.uploadFileSuccess({ media: file, file: payload.file }));
  } catch (error) {
    const t = i18next?.getFixedT(i18n.defaultLocale, 'components:pages:seller-portal');

    if (t) {
      yield* call(toast.error, t('ERRORS.TEXT_ERROR_SENDING_THE_FILE', { name: payload.file.name }));
    }

    yield* put(SellerPortalPageActions.uploadFileFailure({ file: payload.file }));
  }
}

export default function* sellerPortalPageSaga(): SagaIterator {
  yield* takeLatest(SellerPortalPageActions.create, create);
  yield* takeLatest(SellerPortalPageActions.createFailure, createFailure);
  yield* takeEvery(SellerPortalPageActions.uploadFile, uploadFile);
}
