import classNames from 'classnames/bind';
import { useTranslation } from 'next-i18next';
import { DetailedHTMLProps, HTMLAttributes, useEffect, useState } from 'react';
import styles from './layout.module.scss';
import LayoutSidebarItem from './_sidebar_item';
import { useDispatch, useSelector } from 'react-redux';
import { NavigationActions, NavigationSelectors } from 'src/store/navigation';
import { useRouter } from 'next/router';
import Spinner, { SpinnerSize, SpinnerTheme } from '../ui-kit/spinner/spinner';
import { AuthSelectors } from 'src/store/auth';
import { NavigationType } from '../../enums/navigation/type';
import Icon, { IconTheme, IconVariant } from '../ui-kit/icon/icon';

const classesContext = classNames.bind(styles);

export default function LayoutSidebar({
  className,
  children,
  ...restProps
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>): JSX.Element {
  const { t } = useTranslation(['components:layout']);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const leftMenu = useSelector(NavigationSelectors.leftMenu);
  const isLeftMenuLoading = useSelector(NavigationSelectors.isLeftMenuLoading);
  const isAuthenticated = useSelector(AuthSelectors.isAuthenticated);

  const router = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(NavigationActions.loadLeftMenu());
  }, [isAuthenticated]);

  const toggleMobileMenu = (): void => setIsMenuOpen(!isMenuOpen);
  const isProfilePage = leftMenu?.some((item) => item.link === router.asPath);

  const activeProfilePage = leftMenu?.find((item) => item.link === router.asPath);

  return (
    <div
      className={classesContext([className])}
      {...restProps}>
      <div
        className={classesContext(['sidebar', { sidebar__profile: isAuthenticated, sidebar_menu__open: isMenuOpen }])}
      >
        <h3 className={classesContext('sidebar_title', 'sidebar_title__desktop')}>
          {t(`SIDEBAR.${isAuthenticated ? 'TEXT_BUYERS' : 'TEXT_SELLERS'}`)}
        </h3>
        <button
          disabled={isLeftMenuLoading}
          onClick={toggleMobileMenu}
          className={styles.sidebar_button}>
          <h3 className={classesContext('sidebar_title', 'sidebar_title__tablet')}>
            {isLeftMenuLoading ? (
              <div className={styles.sidebar_title_loader}>
                <Spinner
                  size={SpinnerSize.SMALL}
                  theme={SpinnerTheme.WHITE} />
              </div>
            ) : (
              <span>
                {isProfilePage && isAuthenticated
                  ? activeProfilePage?.name
                  : t(`SIDEBAR.${isAuthenticated ? 'TEXT_BUYERS' : 'TEXT_SELLERS'}`)}
              </span>
            )}
            <span className={classesContext('sidebar_button_icon')}>
              <Icon
                variant={isMenuOpen ? IconVariant.DROPUP : IconVariant.DROPDOWN}
                theme={IconTheme.WHITE} />
            </span>
          </h3>
        </button>
        <div className={styles.sidebar_menu}>
          <div className={styles.sidebar_items}>
            {isLeftMenuLoading && (
              <div className={styles.sidebar_items_loader}>
                <Spinner size={SpinnerSize.NORMAL} />
              </div>
            )}
            <div className={styles.sidebar_items_inner}>
              {leftMenu &&
                !isLeftMenuLoading &&
                leftMenu?.map((item) => (
                  <LayoutSidebarItem
                    key={item.id}
                    href={item.link ? item.link : ''}
                    iconVariant={item.type === NavigationType.HEAD ? IconVariant.ITEM : IconVariant.NEXT_SMALL}
                    isActive={isAuthenticated && item.link === router.asPath}
                  >
                    {item.name}
                  </LayoutSidebarItem>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
