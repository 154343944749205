import { Expose } from 'class-transformer';
import { TransformBoolean } from 'src/utils/transform-boolean';
import { TransformDesc } from 'src/utils/transform-desc';

export class PaginationRequest<R = null, OrderBy = null, WC = null> {
  @Expose({ toPlainOnly: true })
  public page?: number;

  @Expose({ name: 'per_page', toPlainOnly: true })
  public perPage?: number;

  @TransformBoolean()
  @Expose({ toPlainOnly: true })
  public all?: boolean;

  @Expose({ toPlainOnly: true })
  public query?: string;

  @Expose({ name: 'order_by', toPlainOnly: true })
  public orderBy?: OrderBy;

  @TransformDesc()
  @Expose({ toPlainOnly: true })
  public desc?: boolean;

  @Expose({ name: 'with', toPlainOnly: true })
  public relations?: Array<R>;

  @Expose({ name: 'with_count', toPlainOnly: true })
  public withCount?: Array<WC>;

  @TransformBoolean()
  @Expose({ name: 'is_featured', toPlainOnly: true })
  public isFeatured?: boolean;

  constructor(model: Partial<PaginationRequest<R, OrderBy, WC>> = {}) {
    Object.assign(this, model);
  }
}
