import { nanoid } from 'nanoid';
import { FileItem } from 'src/types/file-item';

export function getUploadingFilesProgress(
  uploadingFiles: Array<FileItem>,
  payload: { file: File; progress: number }
): Array<FileItem> {
  return uploadingFiles.find((item) => item.file === payload.file)
    ? uploadingFiles.map((item) => (item.file === payload.file ? { ...item, progress: payload.progress } : item))
    : [...uploadingFiles, { id: nanoid(), file: payload.file, progress: 0, isUploaded: false }];
}
