import { createSelector } from 'reselect';
import { AppState } from 'src/store/state';
import { ListingInquiryPageState } from './state';
import { plainToClassFromExist } from 'class-transformer';
import { ServerError } from '../../../entities/server-errors';
import { ListingInquiry } from '../../../entities/listing-inquiry';
import { DTOGroup } from '../../../enums/dto-group';

const selectFeature = (state: AppState): ListingInquiryPageState => state.pages.listingInquiry;

export class ListingInquiryPageSelectors {
  public static isSending = createSelector(
    selectFeature,
    (state) => state.isSending
  );

  public static isSubmitted = createSelector(
    selectFeature,
    (state) => state.isSubmitted
  );

  public static error = createSelector(
    selectFeature,
    (state) => plainToClassFromExist(
      new ServerError<ListingInquiry>(ListingInquiry),
      state.error,
      {
        groups: [DTOGroup.MAIN],
        strategy: 'excludeAll'
      }
    ) as ServerError<ListingInquiry>
  );
}
