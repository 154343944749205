import { listingService } from 'src/services/listing';
import { SagaIterator } from 'redux-saga';
import { takeLatest, call, put, fork, FixedTask } from 'typed-redux-saga';
import { FeaturedListingsActions } from './actions';
import { ListingBanner, ListingOrderBy, ListingRelation, ListingStatus } from 'src/enums/listing';

export let loadFeaturedListingsTask: FixedTask<typeof loadFeaturedListings>;

function* loadFeaturedListings(): SagaIterator {
  try {
    const response = yield* call(listingService.search, {
      page: 1,
      perPage: 3,
      filters: { isFeatured: true, banner: ListingBanner.HIDDEN, status: ListingStatus.ACTIVE },
      relations: [ListingRelation.STATE, ListingRelation.COUNTY],
      orderBy: ListingOrderBy.ID,
      desc: true
    });

    yield* put(FeaturedListingsActions.loadFeaturedListingsSuccess({ response }));
  } catch (error) {
    yield* put(FeaturedListingsActions.loadFeaturedListingsFailure({ error }));
  }
}

function* loadFeaturedListingsForServer(): SagaIterator {
  yield* call(function* () {
    loadFeaturedListingsTask = yield* fork(loadFeaturedListings);
  });
}

export default function* featuredListingsSaga(): SagaIterator {
  yield* takeLatest(FeaturedListingsActions.loadFeaturedListings, loadFeaturedListings);
  yield* takeLatest(FeaturedListingsActions.loadFeaturedListingsForServer, loadFeaturedListingsForServer);
}
