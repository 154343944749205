import { Expose, Type } from 'class-transformer';
import { DTOGroup } from 'src/enums/dto-group';
import { User } from './user';

export class AuthResponse {
  @Expose({ groups: [DTOGroup.MAIN] })
  public token: string;

  @Type(() => Number)
  @Expose({ groups: [DTOGroup.MAIN] })
  public ttl: number;

  @Type(() => Number)
  @Expose({ name: 'refresh_ttl', groups: [DTOGroup.MAIN] })
  public refreshTtl: number;

  @Type(() => User)
  @Expose({ groups: [DTOGroup.MAIN] })
  public user: User;
}
