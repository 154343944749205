import { Expose, Type } from 'class-transformer';
import { DateTime } from 'luxon';
import { DTOGroup } from 'src/enums/dto-group';
import { UserBusinessType } from 'src/enums/sign-agreement';
import { TransformDate } from 'src/utils/transform-date';
import { BaseEntity } from '../base-entity';

export class LegalAgreement extends BaseEntity {
  @Expose({ groups: [DTOGroup.MAIN] })
  public type: UserBusinessType;

  @Expose({ groups: [DTOGroup.MAIN] })
  public name: string;

  @Expose({ name: 'agreement_signed_at', groups: [DTOGroup.MAIN] })
  @Type(() => DateTime)
  @TransformDate()
  public agreementSignedAt: DateTime;

  constructor(model: Partial<LegalAgreement> = {}) {
    super(model);

    Object.assign(this, model);
  }
}
