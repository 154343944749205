import { Media } from 'src/entities/media';
import { action, actionWithPayload } from 'src/store/action-factory';

export class UploadFilesPageActions {
  public static resetState = action(
    '[UploadFilesPage] Reset state'
  );

  public static uploadFile = actionWithPayload<{ file: File }>(
    '[UploadFilesPage] Upload File'
  );

  public static uploadFileSuccess = actionWithPayload<{ media: Media; file: File }>(
    '[UploadFilesPage] Upload File Success'
  );

  public static uploadFileFailure = actionWithPayload<{ file: File }>(
    '[UploadFilesPage] Upload File Failure'
  );

  public static uploadFileProgress = actionWithPayload<{ file: File; progress: number }>(
    '[UploadFilesPage] Upload File Progress'
  );

  public static deleteUploadFile = actionWithPayload<{ file: File }>(
    '[UploadFilesPage] Delete Upload File'
  );
}
