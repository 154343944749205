import { classToPlain, plainToClass, plainToClassFromExist } from 'class-transformer';
import { DTOGroup } from 'src/enums/dto-group';
import { apiService } from './api';
import { ServerError } from 'src/entities/server-errors';
import { ListingInquiry } from 'src/entities/listing-inquiry';

export class ListingInquiryService {
  public async create(listingInquiry: ListingInquiry): Promise<ListingInquiry> {
    const data = classToPlain<ListingInquiry>(listingInquiry, { groups: [DTOGroup.MAIN], strategy: 'excludeAll' });

    try {
      const response = await apiService.post<ListingInquiry>('/listing-inquiries', data);

      return plainToClass(ListingInquiry, response, { groups: [DTOGroup.MAIN] });
    } catch (error) {
      throw plainToClassFromExist(new ServerError<ListingInquiry>(ListingInquiry), error, {
        groups: [DTOGroup.CREATE],
        strategy: 'excludeAll'
      });
    }
  }
}

export const listingInquiryService = new ListingInquiryService();
