import { Exclude, Expose } from 'class-transformer';
import { DTOGroup } from 'src/enums/dto-group';
import { TransformBoolean } from 'src/utils/transform-boolean';
import { BaseEntity } from '../base-entity';

export class Media extends BaseEntity {
  @Expose({ groups: [DTOGroup.MAIN] })
  public name: string;

  @Expose({ groups: [DTOGroup.MAIN] })
  public size: number;

  @Expose({ groups: [DTOGroup.MAIN] })
  public link: string;

  @TransformBoolean()
  @Expose({ name: 'is_public', groups: [DTOGroup.MAIN] })
  public isPublic: boolean;

  @Exclude()
  public get url(): string {
    return `${process.env.NEXT_PUBLIC_API_URL}/media/${this.id}`;
  }

  constructor(model: Partial<Media> = {}) {
    super(model);

    Object.assign(this, model);
  }
}
