import { listingService } from 'src/services/listing';
import { ListingViewPageActions } from './actions';
import { Action } from 'deox';
import { SagaIterator } from 'redux-saga';
import { takeLatest, call, put, fork, FixedTask } from 'typed-redux-saga';
import { ListingRelation } from 'src/enums/listing';
import { i18n as i18next } from 'next-i18next';
import { i18n } from 'next-i18next.config';
import { toast } from 'react-toastify';

export let loadListingViewTask: FixedTask<typeof loadListingView>;

function* loadListingView({
  payload
}: Action<string, typeof ListingViewPageActions.loadListingView.payload>): SagaIterator {
  try {
    const response = yield* call(listingService.getByID, payload.id, {
      relations: payload.relations ?? [ListingRelation.COUNTY, ListingRelation.STATE]
    });

    yield* put(ListingViewPageActions.loadListingViewSuccess({ response }));
  } catch (error) {
    const t = i18next?.getFixedT(i18n.defaultLocale, 'components:pages:listings:view');
    if (t) {
      toast.error(t('ERRORS.TEXT_MESSAGE') as string);
    }
    yield* put(ListingViewPageActions.loadListingViewFailure({ error }));
  }
}

function* loadListingViewForServer(action: Action<string, { id: number }>): SagaIterator {
  yield* call(function* () {
    loadListingViewTask = yield* fork(loadListingView, action);
  });
}

export default function* listingViewPageSaga(): SagaIterator {
  yield* takeLatest(ListingViewPageActions.loadListingView, loadListingView);
  yield* takeLatest(ListingViewPageActions.loadListingViewForServer, loadListingViewForServer);
}
