import { Media } from 'src/entities/media';
import { SellerPortalData } from 'src/entities/seller-portal';
import { ServerError } from 'src/entities/server-errors';
import { action, actionWithPayload } from 'src/store/action-factory';

export class SellerPortalPageActions {
  public static resetState = action(
    '[SellerPortalPage] Reset state'
  );

  public static create = actionWithPayload<{ data: SellerPortalData }>(
    '[SellerPortalPage] Create'
  );

  public static createSuccess = action(
    '[SellerPortalPage] Create success'
  );

  public static createFailure = actionWithPayload<{ error: ServerError<SellerPortalData> | null }>(
    '[SellerPortalPage] Create Failure'
  );

  public static uploadFile = actionWithPayload<{ file: File }>(
    '[SellerPortalPage] Upload File'
  );

  public static uploadFileSuccess = actionWithPayload<{ media: Media; file: File }>(
    '[SellerPortalPage] Upload File Success'
  );

  public static uploadFileFailure = actionWithPayload<{ file: File }>(
    '[SellerPortalPage] Upload File Failure'
  );

  public static uploadFileProgress = actionWithPayload<{ file: File; progress: number }>(
    '[SellerPortalPage] Upload File Progress'
  );

  public static deleteUploadFile = actionWithPayload<{ file: File }>(
    '[SellerPortalPage] Delete Upload File'
  );
}
