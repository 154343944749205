import { ServerError } from 'src/entities/server-errors';
import { ChangePasswordData, User } from 'src/entities/user';
import { action, actionWithPayload } from 'src/store/action-factory';

export class ProfilePageActions {
  public static resetState = action(
    '[ProfilePage] Reset state'
  );

  public static edit = actionWithPayload<{ profile: User; messageSuccess?: string }>(
    '[ProfilePage] Edit Profile'
  );

  public static editSuccess = action(
    '[ProfilePage] Edit Profile Success'
  );

  public static editFailure = actionWithPayload<{ error: ServerError<User> | null }>(
    '[ProfilePage] Edit Profile Failure'
  );

  public static changePassword = actionWithPayload<{ data: ChangePasswordData }>(
    '[ProfilePage] Change Password'
  );

  public static changePasswordSuccess = action(
    '[ProfilePage] Change Password Success'
  );

  public static changePasswordFailure = actionWithPayload<{ error: ServerError<User> | null }>(
    '[ProfilePage] Change Password Failure'
  );
}
