import { Expose } from 'class-transformer';
import { DTOGroup } from 'src/enums/dto-group';
import { UserEmailDeliveryFrequency } from 'src/enums/user';

export class UnsubscribeData {
  @Expose({ name: 'email_delivery_frequency', groups: [DTOGroup.UPDATE, DTOGroup.MAIN] })
  public emailDeliveryFrequency: UserEmailDeliveryFrequency;

  constructor(model: Partial<UnsubscribeData> = {}) {
    Object.assign(this, model);
  }
}
