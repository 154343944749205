import { createSelector } from 'reselect';
import { AppState } from 'src/store/state';
import { UploadFilesPageState } from './state';

const selectFeature = (state: AppState): UploadFilesPageState => state.pages.uploadFiles;

export class UploadFilesPageSelectors {
  public static files = createSelector(
    selectFeature,
    (state) => state.files
  );
}
