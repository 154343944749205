import { plainToClass } from 'class-transformer';
import { Navigation } from 'src/entities/navigation/navigation';
import { DTOGroup } from 'src/enums/dto-group';
import { NavigationMenuName } from 'src/enums/navigation/name-menu';
import { apiService } from './api';

const endpoint = '/navigation';

export class NavigationService {
  public async getNavigation(menuName: NavigationMenuName): Promise<Navigation> {
    const response = await apiService.get<Navigation>(`${endpoint}/${menuName}`);

    return plainToClass(Navigation, response, {
      groups: [DTOGroup.MAIN],
      strategy: 'excludeAll'
    });
  }
}

export const navigationService = new NavigationService();
