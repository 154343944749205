import classNames from 'classnames/bind';
import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import styles from './box.module.scss';

const classesContext = classNames.bind(styles);

export enum HeaderTheme {
  PRIMARY = 'primary',
  LIGHT = 'light',
  DARK = 'dark'
}

export enum HeadingElement {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6'
}

export default function Box({
  children,
  heading,
  className,
  headingTheme,
  headingElement = HeadingElement.H3,
  ...restProps
}: {
  heading?: ReactNode;
  headingTheme?: HeaderTheme;
  headingElement?: HeadingElement;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>): JSX.Element {
  const Heading = headingElement;

  return (
    <div
      className={classesContext(['box', className])}
      {...restProps}>
      {!!heading && (
        <Heading className={classesContext(['title', `box_title__${headingTheme ? headingTheme : 'primary'}`])}>
          {heading}
        </Heading>
      )}
      {children}
    </div>
  );
}
