import Router from 'next/router';
import { authService } from 'src/services/auth';
import { RestorePasswordPageActions } from './actions';
import { Action } from 'deox';
import { SagaIterator } from 'redux-saga';
import { takeLatest, call, put } from 'typed-redux-saga';
import { toast } from 'react-toastify';
import { i18n as i18next } from 'next-i18next';
import { i18n } from 'next-i18next.config';

function* forgotPassword({
  payload
}: Action<string, typeof RestorePasswordPageActions.forgotPassword.payload>): SagaIterator {
  try {
    yield* call(authService.forgot, payload.email);
    Router.push('/');

    yield* put(RestorePasswordPageActions.forgotPasswordSuccess());

    const t = i18next?.getFixedT(i18n.defaultLocale, 'components:pages:restore-password');
    if (t) {
      toast.success(t('TEXT_MESSAGE_SUCCESS', { email: payload.email }) as string);
    }
  } catch (error) {
    yield* put(RestorePasswordPageActions.forgotPasswordFailure({ error }));
  }
}

export default function* restorePasswordPageSaga(): SagaIterator {
  yield* takeLatest(RestorePasswordPageActions.forgotPassword, forgotPassword);
}
