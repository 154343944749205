import { ServerError } from 'src/entities/server-errors';
import { RestorePasswordData } from 'src/entities/user';
import { action, actionWithPayload } from 'src/store/action-factory';

export class EnterNewPasswordPageActions {
  public static resetState = action(
    '[EnterNewPasswordPage] Reset state'
  );

  public static restorePassword = actionWithPayload<RestorePasswordData>(
    '[EnterNewPasswordPage] Restore password'
  );

  public static restorePasswordSuccess = action(
    '[EnterNewPasswordPage] Restore password success'
  );

  public static restorePasswordFailure = actionWithPayload<{ error: ServerError<RestorePasswordData> | null }>(
    '[EnterNewPasswordPage] Restore password Failure'
  );

  public static checkToken = actionWithPayload<{ token: string }>(
    '[EnterNewPasswordPage] Check token'
  );

  public static checkTokenForServer = actionWithPayload<{ token: string }>(
    '[EnterNewPasswordPage] Check token For Server'
  );

  public static checkTokenSuccess = action(
    '[EnterNewPasswordPage] Check token success'
  );

  public static checkTokenFailure = actionWithPayload<{ error: ServerError<RestorePasswordData> | null }>(
    '[EnterNewPasswordPage] Check token Failure'
  );
}
