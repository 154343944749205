import classNames from 'classnames/bind';
import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef, Ref } from 'react';
import styles from './button.module.scss';

const classesContext = classNames.bind(styles);

export enum ButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  OUTLINE = 'outline',
  DANGER = 'danger',
  TERTIARY = 'tertiary'
}

export enum ButtonSize {
  SMALL = 'small',
  NORMAL = 'normal',
  LARGE = 'large'
}

export interface ButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  variant?: ButtonVariant;
  size?: ButtonSize;
  isLoading?: boolean;
}

function Button(
  { variant, size, isLoading, className, ...restProps }: ButtonProps,
  ref: Ref<HTMLButtonElement>
): JSX.Element {
  return (
    <button
      className={classesContext([
        'button',
        `button__${variant ? variant : 'primary'}`,
        `button__${size ? size : 'normal'}`,
        { button__loading: isLoading },
        className
      ])}
      ref={ref}
      {...restProps}
    />
  );
}

export default forwardRef(Button);
