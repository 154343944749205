import { classToPlain, plainToClass, plainToClassFromExist } from 'class-transformer';
import { ServerError } from 'src/entities/server-errors';
import { UnsubscribeData } from 'src/entities/unsubscribe/unsubscribe-data';
import { DTOGroup } from 'src/enums/dto-group';
import { apiService } from './api';

export class EmailDeliveryFrequencyService {
  public async unsubscribe(authKey: string, unsubscribeData: UnsubscribeData): Promise<void> {
    try {
      return apiService.put<void>(
        `/email-delivery-frequency/${authKey}`,
        classToPlain<UnsubscribeData>(unsubscribeData, { groups: [DTOGroup.UPDATE], strategy: 'excludeAll' })
      );
    } catch (error) {
      throw plainToClassFromExist(new ServerError<UnsubscribeData>(UnsubscribeData), error, {
        groups: [DTOGroup.UPDATE],
        strategy: 'excludeAll'
      });
    }
  }

  public async checkAuthKey(authKey: string): Promise<UnsubscribeData> {
    try {
      const response = await apiService.get<UnsubscribeData>(`/email-delivery-frequency/${authKey}`);

      return plainToClass(UnsubscribeData, response, {
        groups: [DTOGroup.MAIN],
        strategy: 'excludeAll'
      });
    } catch (error) {
      throw plainToClassFromExist(new ServerError<UnsubscribeData>(UnsubscribeData), error, {
        groups: [DTOGroup.MAIN],
        strategy: 'excludeAll'
      });
    }
  }
}

export const emailDeliveryFrequencyService = new EmailDeliveryFrequencyService();
