import { classToPlain, plainToClass, plainToClassFromExist } from 'class-transformer';
import { ChangePasswordData, User } from 'src/entities/user';
import { DTOGroup } from 'src/enums/dto-group';
import { apiService } from './api';
import { ServerError } from 'src/entities/server-errors';

const endpoint = '/profile';

export class ProfileService {
  public async update(user: User): Promise<void> {
    const data = classToPlain<User>(user, { groups: [DTOGroup.UPDATE], strategy: 'excludeAll' });

    try {
      return apiService.put<void>(endpoint, data);
    } catch (error) {
      throw plainToClassFromExist(new ServerError<User>(User), error, {
        groups: [DTOGroup.UPDATE],
        strategy: 'excludeAll'
      });
    }
  }

  public async changePassword(changeData: ChangePasswordData): Promise<void> {
    const data = classToPlain<ChangePasswordData>(changeData, { groups: [DTOGroup.MAIN], strategy: 'excludeAll' });

    try {
      return apiService.put<void>(endpoint, data);
    } catch (error) {
      throw plainToClassFromExist(new ServerError<ChangePasswordData>(ChangePasswordData), error, {
        groups: [DTOGroup.MAIN],
        strategy: 'excludeAll'
      });
    }
  }

  public async refresh(): Promise<User> {
    const response = await apiService.get<User>(endpoint);

    return plainToClass(User, response, { groups: [DTOGroup.MAIN], strategy: 'excludeAll' });
  }
}

export const profileService = new ProfileService();
