import { combineReducers } from 'redux';
import { authReducer } from './auth/reducer';
import { listingInquiryPageReducer } from './pages/listing-inquiry';
import { contactSupportPageReducer } from './pages/contact-support';
import { listingViewPageReducer } from './pages/listing-view/reducer';
import { mineralListingsPageReducer } from './pages/mineral-listings/reducer';
import { profilePageReducer } from './pages/profile/reducer';
import { restorePasswordPageReducer } from './pages/restore-password/reducer';
import { signUpPageReducer } from './pages/sign-up/reducer';
import { profileReducer } from './profile/reducer';
import { sellerPortalPageReducer } from './pages/seller-portal';
import { featuredListingsReducer } from './featured-listings';
import { navigationReducer } from './navigation';
import { signAccessAgreementPageReducer } from './pages/sign-access-agreement';
import { enterNewPasswordPageReducer } from './pages/enter-new-password';
import { uploadFilesPageReducer } from './pages/upload-files';
import { unsubscribePageReducer } from './pages/unsubscribe';

export const rootReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  navigation: navigationReducer,
  featuredListings: featuredListingsReducer,
  pages: combineReducers({
    signUp: signUpPageReducer,
    profile: profilePageReducer,
    restorePassword: restorePasswordPageReducer,
    mineralListings: mineralListingsPageReducer,
    listingView: listingViewPageReducer,
    listingInquiry: listingInquiryPageReducer,
    contactSupport: contactSupportPageReducer,
    sellerPortal: sellerPortalPageReducer,
    signAccessAgreement: signAccessAgreementPageReducer,
    enterNewPassword: enterNewPasswordPageReducer,
    uploadFiles: uploadFilesPageReducer,
    unsubscribe: unsubscribePageReducer
  })
});
