import { classToPlain, plainToClass, plainToClassFromExist } from 'class-transformer';
import { State, StatePaginationRequest } from 'src/entities/state';
import { PaginationResponse } from 'src/entities/pagination';
import { DTOGroup } from 'src/enums/dto-group';
import { apiService } from './api';
import { StateOrderBy, StateRelation, StateWithCount } from 'src/enums/state';

const endpoint = '/states';

export class StateService {
  public async search({
    ...params
  }: {
    page?: number;
    perPage?: number;
    orderBy?: StateOrderBy;
    desc?: boolean;
    all?: boolean;
    relations?: Array<StateRelation>;
    withCount?: Array<StateWithCount>;
  } = {}): Promise<PaginationResponse<State>> {
    const request = new StatePaginationRequest({ ...params });
    const response = await apiService.get<PaginationResponse<State>>(
      endpoint,
      classToPlain<StatePaginationRequest>(request)
    );

    return plainToClassFromExist(new PaginationResponse<State>(State), response, {
      groups: [DTOGroup.MAIN],
      strategy: 'excludeAll'
    });
  }

  public async getByID(id: number): Promise<State> {
    const response = await apiService.get<State>(`${endpoint}/${id}`);

    return plainToClass(State, response, {
      groups: [DTOGroup.MAIN],
      strategy: 'excludeAll'
    });
  }
}

export const stateService = new StateService();
