import { HYDRATE } from 'next-redux-wrapper';
import { action, actionWithPayload } from './action-factory';
import { AppState } from './state';

export class AppActions {
  public static init = action(
    '[App] Init'
  );

  public static noop = action(
    '[App] Noop'
  );

  public static hydrate = actionWithPayload<AppState>(
    HYDRATE
  );
}
