import { Expose } from 'class-transformer';
import { DTOGroup } from 'src/enums/dto-group';

export class UploadedFilesData {
  @Expose({ name: 'media_id', groups: [DTOGroup.CREATE] })
  public mediaID: number;

  constructor(model: Partial<UploadedFilesData> = {}) {
    Object.assign(this, model);
  }
}
