import { Transform } from 'class-transformer';

export function TransformBoolean(): Function {
  const toPlain = Transform(
    ({ value }): number | undefined => {
      if (value) {
        return 1;
      } else if (typeof value === 'boolean') {
        return 0;
      }

      return undefined;
    },
    { toPlainOnly: true }
  );

  return (target: any, key: string): void => {
    toPlain(target, key);
  };
}
