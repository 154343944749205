export function isSlugContainsPage(slugItem: string): boolean {
  return slugItem?.includes('page') && !!Number(slugItem?.slice(4, slugItem.length));
}

export function prepareListingRouteSlug(slug: Array<string>): Array<string> {
  const currentSlug = slug.filter((item) => item !== 'sort' && !isSlugContainsPage(item));
  const pageIndex = slug.findIndex((item) => isSlugContainsPage(item));

  if (pageIndex !== -1 && !Number(currentSlug[0])) {
    currentSlug.unshift(slug[pageIndex].slice(4, slug[pageIndex].length));
  }

  let sortIndex = slug.findIndex((item) => item === 'sort');

  if (sortIndex !== -1) {
    sortIndex = sortIndex === 0 ? 1 : sortIndex;

    const [page, state, county]: Array<string> = currentSlug.slice(0, sortIndex);

    const [order, desc]: Array<string> = currentSlug.slice(sortIndex);

    return [page, state, county, order, desc];
  }

  return currentSlug;
}
