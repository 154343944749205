import { plainToClass } from 'class-transformer';
import { createSelector } from 'reselect';
import { Listing } from 'src/entities/listing';
import { DTOGroup } from 'src/enums/dto-group';
import { AppState } from 'src/store/state';
import { FeaturedListingsState } from './state';

const selectFeature = (state: AppState): FeaturedListingsState => state.featuredListings;

export class FeaturedListingsSelectors {
  public static isLoading = createSelector(
    selectFeature,
    (state) => state.isLoading
  );

  public static items = createSelector(
    selectFeature,
    (state) => plainToClass(
      Listing,
      state.items,
      { groups: [DTOGroup.MAIN], strategy: 'excludeAll' }
    )
  );
}
