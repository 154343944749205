import { Expose } from 'class-transformer';
import { UserRelation } from 'src/enums/user/relation';
import { TransformBoolean } from '../../utils/transform-boolean';

export class LoginRequest {
  @Expose({ name: 'with', toPlainOnly: true })
  public relations?: Array<UserRelation>;

  @TransformBoolean()
  @Expose({ toPlainOnly: true })
  public remember = true;

  constructor(model: Partial<LoginRequest> = {}) {
    Object.assign(this, model);
  }
}
