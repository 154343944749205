import { ServerError } from 'src/entities/server-errors';
import { User } from 'src/entities/user';
import { action, actionWithPayload } from 'src/store/action-factory';

export class RestorePasswordPageActions {
  public static resetState = action(
    '[RestorePasswordPage] Reset state'
  );

  public static forgotPassword = actionWithPayload<{ email: string }>(
    '[RestorePasswordPage] Forgot password'
  );

  public static forgotPasswordSuccess = action(
    '[RestorePasswordPage] Forgot password success'
  );

  public static forgotPasswordFailure = actionWithPayload<{ error: ServerError<User> | null }>(
    '[RestorePasswordPage] Forgot password Failure'
  );
}
