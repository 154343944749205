export class ListingInquiryPageState {
  public isSending: boolean;
  public isSubmitted: boolean;
  public error: object | null;

  constructor() {
    this.isSending = false;
    this.isSubmitted = false;
    this.error = null;
  }
}
