export class UnsubscribePageState {
  public userEmailDeliveryFrequency: object | null;
  public isSending: boolean;
  public error: object | null;

  constructor() {
    this.userEmailDeliveryFrequency = null;
    this.isSending = false;
    this.error = null;
  }
}
