import { Expose } from 'class-transformer';
import { ContactSupportIssueType } from 'src/enums/contact-support-issue-type';
import { DTOGroup } from 'src/enums/dto-group';

export class ContactSupportData {
  @Expose({ groups: [DTOGroup.MAIN] })
  public type: ContactSupportIssueType;

  @Expose({ groups: [DTOGroup.MAIN] })
  public question: string;

  constructor(model: Partial<ContactSupportData> = {}) {
    Object.assign(this, model);
  }
}
