import { ListingBanner, ListingStatus } from 'src/enums/listing';
import { ListingFilterKeys } from 'src/enums/listing/filter-keys';

export class ListingFilters {
  public query?: string;
  public statesIDs?: number | Array<number>;
  public countiesIDs?: number | Array<number>;
  public isCashFlow?: boolean;
  public isFeatured?: boolean;
  public status?: ListingStatus;
  public banner?: ListingBanner;
  public listings?: ListingFilterKeys;

  constructor(model: Partial<ListingFilters> = {}) {
    Object.assign(this, model);
  }
}
