import { Action, createReducer } from 'deox';
import { RestorePasswordPageActions } from './actions';
import { RestorePasswordPageState } from './state';
import { classToPlain } from 'class-transformer';
import { DTOGroup } from '../../../enums/dto-group';

const initialState = new RestorePasswordPageState();

const reducer = createReducer(initialState, (handleAction) => [
  handleAction(RestorePasswordPageActions.resetState, () => initialState),
  handleAction(RestorePasswordPageActions.forgotPassword, (state) => ({
    ...state,
    error: null,
    isSending: true
  })),
  handleAction(RestorePasswordPageActions.forgotPasswordSuccess, (state) => ({
    ...state,
    isSending: false
  })),
  handleAction(RestorePasswordPageActions.forgotPasswordFailure, (state, { payload }) => ({
    ...state,
    error: classToPlain(payload.error, { groups: [DTOGroup.MAIN], strategy: 'excludeAll' }),
    isSending: false
  }))
]);

export function restorePasswordPageReducer(
  state: RestorePasswordPageState | undefined,
  action: Action<any>
): RestorePasswordPageState {
  return reducer(state, action);
}
