import { createSelector } from 'reselect';
import { AppState } from 'src/store/state';
import { SignAccessAgreementPageState } from './state';
import { State } from 'src/entities/state';
import { plainToClass } from 'class-transformer';
import { DTOGroup } from '../../../enums/dto-group';

const selectFeature = (state: AppState): SignAccessAgreementPageState => state.pages.signAccessAgreement;

export class SignAccessAgreementPageSelectors {
  public static isStatesLoading = createSelector(
    selectFeature,
    (state) => state.isStatesLoading
  );

  public static states = createSelector(
    selectFeature,
    (state) => plainToClass(
      State,
      state.states,
      { groups: [DTOGroup.MAIN], strategy: 'excludeAll' }
    )
  );

  public static isSending = createSelector(
    selectFeature,
    (state) => state.isSending
  );
}
