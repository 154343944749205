import { ServerError } from 'src/entities/server-errors';
import { UnsubscribeData } from 'src/entities/unsubscribe/unsubscribe-data';
import { action, actionWithPayload } from 'src/store/action-factory';

export class UnsubscribePageActions {
  public static resetState = action(
    '[UnsubscribePage] Reset state'
  );

  public static unsubscribe = actionWithPayload<{
    authKey: string;
    unsubscribeData: UnsubscribeData;
  }>(
    '[UnsubscribePage] Unsubscribe'
  );

  public static unsubscribeSuccess = action(
    '[UnsubscribePage] Unsubscribe Success'
  );

  public static unsubscribeFailure = actionWithPayload<{ error: ServerError<UnsubscribeData> | null }>(
    '[UnsubscribePage] Unsubscribe Failure'
  );

  public static checkAuthKey = actionWithPayload<{ authKey: string }>(
    '[UnsubscribePage] Check AuthKey'
  );

  public static checkAuthKeyForServer = actionWithPayload<{ authKey: string }>(
    '[UnsubscribePage] Check AuthKey For Server'
  );

  public static checkAuthKeySuccess = actionWithPayload<{ response: UnsubscribeData }>(
    '[UnsubscribePage] Check AuthKey Success'
  );

  public static checkAuthKeyFailure = actionWithPayload<{ error: ServerError<UnsubscribeData> | null }>(
    '[UnsubscribePage] Check AuthKey Failure'
  );
}
