import { Expose } from 'class-transformer';
import { DTOGroup } from 'src/enums/dto-group';
import { TransformBoolean } from 'src/utils/transform-boolean';

export class ListingInquiry {
  @Expose({ name: 'listing_id', groups: [DTOGroup.MAIN] })
  public listingId: number;

  @Expose({ groups: [DTOGroup.MAIN] })
  public comment: string;

  @TransformBoolean()
  @Expose({ name: 'send_copy', groups: [DTOGroup.MAIN] })
  public isSendCopy: boolean;

  constructor(model: Partial<ListingInquiry> = {}) {
    Object.assign(this, model);
  }
}
