import { ListingFilterKeys } from 'src/enums/listing';

export class ListingFiltersQueryParameters {
  public query?: string;
  public statesIDs?: number | Array<number>;
  public countiesIDs?: number | Array<number>;
  public isCashFlow?: boolean;
  public listings?: ListingFilterKeys;

  constructor(model: Partial<ListingFiltersQueryParameters> = {}) {
    Object.assign(this, model);
  }
}
