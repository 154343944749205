import { classToPlain, plainToClass, plainToClassFromExist } from 'class-transformer';
import { AuthResponse, RegisterData, RestorePasswordData, User } from 'src/entities/user';
import { DTOGroup } from 'src/enums/dto-group';
import { apiService } from './api';
import { ServerError } from 'src/entities/server-errors';
import { configuration } from '../configurations/configuration';
import { LoginRequest } from 'src/entities/user/login-request';
import { UserRelation } from 'src/enums/user/relation';

export class AuthService {
  public logoutPromise: Promise<unknown> | null;
  private tokenRefreshPromise: Promise<unknown> | null;

  constructor() {
    this.tokenRefreshPromise = null;
    this.logoutPromise = null;
  }

  public async login(user: User, relations?: Array<UserRelation>): Promise<AuthResponse> {
    const data = classToPlain<User>(user, { groups: [DTOGroup.MAIN] });
    const request = new LoginRequest({ relations });

    const response = await apiService.post<AuthResponse>('/login', data, {
      params: classToPlain<LoginRequest>(request)
    });

    return plainToClass(AuthResponse, response, { groups: [DTOGroup.MAIN], strategy: 'excludeAll' });
  }

  public async forgot(email: string): Promise<void> {
    try {
      return await apiService.post<void>('/auth/forgot-password', { email });
    } catch (error) {
      throw plainToClassFromExist(new ServerError<User>(User), error, {
        groups: [DTOGroup.MAIN],
        strategy: 'excludeAll'
      });
    }
  }

  public async checkToken(token: string): Promise<void> {
    try {
      return await apiService.post<void>('/auth/token/check', { token });
    } catch (error) {
      throw plainToClassFromExist(new ServerError<RestorePasswordData>(RestorePasswordData), error, {
        groups: [DTOGroup.MAIN],
        strategy: 'excludeAll'
      });
    }
  }

  public restorePassword(restoreData: RestorePasswordData): Promise<void> {
    const data = classToPlain<RestorePasswordData>(restoreData, { groups: [DTOGroup.MAIN] });

    return apiService.post<void>('/auth/restore-password', data);
  }

  public async register(registerData: RegisterData): Promise<AuthResponse> {
    const data = classToPlain<RegisterData>(registerData, { groups: [DTOGroup.CREATE], strategy: 'excludeAll' });

    try {
      const response = await apiService.post<AuthResponse>('/register', data);

      return plainToClass(AuthResponse, response, { groups: [DTOGroup.MAIN], strategy: 'excludeAll' });
    } catch (error) {
      throw plainToClassFromExist(new ServerError<User>(User), error, {
        groups: [DTOGroup.MAIN],
        strategy: 'excludeAll'
      });
    }
  }

  public refreshToken(): Promise<unknown> {
    if (!this.tokenRefreshPromise) {
      this.tokenRefreshPromise = apiService.get('/auth/refresh')
        .finally(() => {
          this.tokenRefreshPromise = null;
        });
    }

    return this.tokenRefreshPromise;
  }

  public logout(): Promise<unknown> {
    if (!this.logoutPromise) {
      this.logoutPromise = apiService.post(configuration.auth.logoutRoute)
        .finally(() => {
          this.logoutPromise = null;
        });
    }

    return this.logoutPromise;
  }
}

export const authService = new AuthService();
