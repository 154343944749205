import { Exclude, Expose, Type } from 'class-transformer';
import { kebabCase, startCase } from 'lodash';
import { DTOGroup } from 'src/enums/dto-group';
import { BaseEntity } from '../base-entity';
import { County } from '../county';

export class State extends BaseEntity {
  @Expose({ groups: [DTOGroup.MAIN] })
  public name: string;

  @Expose({ groups: [DTOGroup.MAIN] })
  public code: string;

  @Type(() => County)
  @Expose({ groups: [DTOGroup.MAIN] })
  public counties: Array<County>;

  @Expose({ name: 'listings_count', groups: [DTOGroup.MAIN] })
  public listingsCount: number;

  @Exclude()
  public get nameInKebabCase(): string {
    return kebabCase(this.name);
  }

  @Exclude()
  public get nameLowerCase(): string {
    return this.name.toLocaleLowerCase();
  }

  public get nameInKebabCapitalizeCase(): string {
    return startCase(this.name)
      .trim()
      .split(' ')
      .join('-');
  }

  constructor(model: Partial<State> = {}) {
    super(model);

    Object.assign(this, model);
  }
}
