import classNames from 'classnames/bind';
import { DetailedHTMLProps, HTMLAttributes, useEffect, useState } from 'react';
import styles from './layout.module.scss';
import LayoutFooter from './_footer';
import LayoutHeader from './_header';
import LayoutMenu from './_menu';
import LayoutSidebar from './_sidebar';
import ToastNotification from 'src/components/ui-kit/toast-notification/toast-notification';
import LayoutHeaderLinks from './_header_links';
import { useRouter } from 'next/router';

const classesContext = classNames.bind(styles);

export enum LayoutType {
  HOME,
  ACCOUNT
}

export default function Layout({
  children,
  ...restProps
}: { children: JSX.Element } & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>): JSX.Element {
  const layoutType: LayoutType = children?.props?.Component?.layout ?? LayoutType.HOME;

  const router = useRouter();

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isFullScreenSearchOpen, setIsFullScreenSearchOpen] = useState<boolean>(false);

  const toggleMobileMenu = (): void => {
    if (isFullScreenSearchOpen) {
      setIsFullScreenSearchOpen(false);
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(!isMenuOpen);
    }
  };

  const openFullScreenSearch = (): void => {
    setIsFullScreenSearchOpen(true);
  };

  useEffect(() => {
    if (isFullScreenSearchOpen) {
      setIsMenuOpen(false);
    }
  }, [isFullScreenSearchOpen]);

  useEffect(() => {
    setIsMenuOpen(false);
    setIsFullScreenSearchOpen(false);
  }, [router.asPath]);

  return (
    <div
      className={styles.account}
      {...restProps}>
      <header
        className={classesContext('account_inner', {
          account_inner__open: isMenuOpen,
          account_inner__search: isFullScreenSearchOpen
        })}
      >
        <LayoutHeader
          isMenuOpen={isMenuOpen}
          isFullScreenSearchOpen={isFullScreenSearchOpen}
          onToggleMobileMenu={toggleMobileMenu}
          onOpenFullScreenSearch={openFullScreenSearch}
        />
        <LayoutMenu isFullScreenSearchOpen={isFullScreenSearchOpen} />
        <div className={styles.account_linksmobile}>
          <LayoutHeaderLinks />
        </div>
      </header>
      <div className='container'>
        <div className={styles.account_content}>
          {layoutType === LayoutType.HOME && <div className={styles.body_content}>{children}</div>}
          {layoutType === LayoutType.ACCOUNT && (
            <div className={classesContext(['body', 'body__showsidebar'])}>
              <LayoutSidebar className={styles.body_sidebar} />
              <div className={styles.body_content}>{children}</div>
            </div>
          )}
        </div>
      </div>
      <footer className={styles.footer}>
        <LayoutFooter />
      </footer>
      <ToastNotification />
    </div>
  );
}
