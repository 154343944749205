import { SagaIterator } from 'redux-saga';
import { takeLatest, call, put, fork, FixedTask } from 'typed-redux-saga';
import { NavigationActions } from './actions';
import { navigationService } from 'src/services/navigation';
import { NavigationMenuName } from 'src/enums/navigation/name-menu';

export let loadFooterMenuTask: FixedTask<typeof loadFooterMenu>;
export let loadLeftMenuTask: FixedTask<typeof loadLeftMenu>;
export let loadHomeMenuTask: FixedTask<typeof loadHomeMenu>;

function* loadFooterMenu(): SagaIterator {
  try {
    const response = yield* call(navigationService.getNavigation, NavigationMenuName.FOOTER);

    yield* put(NavigationActions.loadFooterMenuSuccess({ response }));
  } catch (error) {
    yield* put(NavigationActions.loadFooterMenuFailure({ error }));
  }
}

function* loadFooterMenuForServer(): SagaIterator {
  yield* call(function* () {
    loadFooterMenuTask = yield* fork(loadFooterMenu);
  });
}

function* loadLeftMenu(): SagaIterator {
  try {
    const response = yield* call(navigationService.getNavigation, NavigationMenuName.LEFT);

    yield* put(NavigationActions.loadLeftMenuSuccess({ response }));
  } catch (error) {
    yield* put(NavigationActions.loadLeftMenuFailure({ error }));
  }
}

function* loadLeftMenuForServer(): SagaIterator {
  yield* call(function* () {
    loadLeftMenuTask = yield* fork(loadLeftMenu);
  });
}

function* loadHomeMenu(): SagaIterator {
  try {
    const response = yield* call(navigationService.getNavigation, NavigationMenuName.HOME);

    yield* put(NavigationActions.loadHomeMenuSuccess({ response }));
  } catch (error) {
    yield* put(NavigationActions.loadHomeMenuFailure({ error }));
  }
}

function* loadHomeMenuForServer(): SagaIterator {
  yield* call(function* () {
    loadHomeMenuTask = yield* fork(loadHomeMenu);
  });
}

export default function* navigationSaga(): SagaIterator {
  yield* takeLatest(NavigationActions.loadFooterMenu, loadFooterMenu);
  yield* takeLatest(NavigationActions.loadFooterMenuForServer, loadFooterMenuForServer);
  yield* takeLatest(NavigationActions.loadLeftMenu, loadLeftMenu);
  yield* takeLatest(NavigationActions.loadLeftMenuForServer, loadLeftMenuForServer);
  yield* takeLatest(NavigationActions.loadHomeMenu, loadHomeMenu);
  yield* takeLatest(NavigationActions.loadHomeMenuForServer, loadHomeMenuForServer);
}
