import { Expose } from 'class-transformer';
import { DTOGroup } from 'src/enums/dto-group';

export class ChangePasswordData {
  @Expose({ name: 'old_password', groups: [DTOGroup.MAIN] })
  public oldPassword: string;

  @Expose({ groups: [DTOGroup.MAIN] })
  public password: string;

  @Expose({ name: 'password_confirmation', groups: [DTOGroup.MAIN] })
  public confirm: string;

  constructor(model: Partial<ChangePasswordData> = {}) {
    Object.assign(this, model);
  }
}
