import { ServerError } from 'src/entities/server-errors';
import { AuthResponse, RegisterData, User } from 'src/entities/user';
import { action, actionWithPayload } from 'src/store/action-factory';

export class SignUpPageActions {
  public static resetState = action(
    '[SignUpPage] Reset state'
  );

  public static register = actionWithPayload<{ registerData: RegisterData }>(
    '[SignUpPage] Register'
  );

  public static registerSuccess = actionWithPayload<{ response: AuthResponse }>(
    '[SignUpPage] Register Success'
  );

  public static registerFailure = actionWithPayload<{ error: ServerError<User> | null }>(
    '[SignUpPage] Register Failure'
  );
}
