import { ServerError } from 'src/entities/server-errors';
import { User } from 'src/entities/user';
import { SignAgreementResponse } from 'src/entities/user/sign-agreement-response';
import { action, actionWithPayload } from 'src/store/action-factory';

export class ProfileActions {
  public static refresh = action(
    '[Profile] Refresh profile'
  );

  public static refreshSuccess = actionWithPayload<{ profile: User }>(
    '[Profile] Refresh profile Success'
  );

  public static refreshFailure = action(
    '[Profile] Refresh profile Failure'
  );

  public static upsertState = actionWithPayload<{ profile: Partial<User> }>(
    '[Profile] Upsert State'
  );

  public static clearState = action(
    '[Profile] Clear State'
  );

  public static setIsAgreementSigned = actionWithPayload<{ isAgreementSigned: boolean }>(
    '[Profile] Set Is Agreement Signed'
  );

  public static checkSignedAgreementForServer = actionWithPayload<{ userId: string }>(
    '[Profile] Check Signed Agreement For Server'
  );

  public static checkSignedAgreementSuccess = actionWithPayload<{ response: SignAgreementResponse }>(
    '[Profile] Check Signed Agreement Success'
  );

  public static checkSignedAgreementFailure = actionWithPayload<{ error: ServerError<SignAgreementResponse> | null }>(
    '[Profile] Check Signed Agreement Failure'
  );
}
