import Router from 'next/router';
import { UnsubscribePageActions } from './actions';
import { Action } from 'deox';
import { SagaIterator } from 'redux-saga';
import { takeLatest, call, put, fork, FixedTask } from 'typed-redux-saga';
import { toast } from 'react-toastify';
import { i18n as i18next } from 'next-i18next';
import { i18n } from 'next-i18next.config';
import { emailDeliveryFrequencyService } from 'src/services/email-delivery-frequency';

export let checkAuthKeyTask: FixedTask<typeof checkAuthKey>;

function* unsubscribe({ payload }: Action<string, typeof UnsubscribePageActions.unsubscribe.payload>): SagaIterator {
  try {
    yield* call(emailDeliveryFrequencyService.unsubscribe, payload.authKey, payload.unsubscribeData);

    Router.push('/');

    const t = i18next?.getFixedT(i18n.defaultLocale, 'components:pages:unsubscribe');
    if (t) {
      toast.success(t('TEXT_MESSAGE_SUCCESS') as string);
    }

    yield* put(UnsubscribePageActions.unsubscribeSuccess());
  } catch (error) {
    yield* put(UnsubscribePageActions.unsubscribeFailure({ error }));
  }
}

function* unsubscribeFailure(): SagaIterator {
  const t = i18next?.getFixedT(i18n.defaultLocale, 'components:pages:unsubscribe');

  if (t) {
    yield* call(toast.error, t('TEXT_MESSAGE_ERROR'));
  }
}

function* checkAuthKey({ payload }: Action<string, typeof UnsubscribePageActions.checkAuthKey.payload>): SagaIterator {
  try {
    const response = yield* call(emailDeliveryFrequencyService.checkAuthKey, payload.authKey);

    yield* put(UnsubscribePageActions.checkAuthKeySuccess({ response }));
  } catch (error) {
    yield* put(UnsubscribePageActions.checkAuthKeyFailure({ error }));
  }
}

function* checkAuthKeyForServer(action: Action<string, { authKey: string }>): SagaIterator {
  yield* call(function* () {
    checkAuthKeyTask = yield* fork(checkAuthKey, action);
  });
}

export default function* unsubscribePageSaga(): SagaIterator {
  yield* takeLatest(UnsubscribePageActions.unsubscribe, unsubscribe);
  yield* takeLatest(UnsubscribePageActions.unsubscribeFailure, unsubscribeFailure);
  yield* takeLatest(UnsubscribePageActions.checkAuthKey, checkAuthKey);
  yield* takeLatest(UnsubscribePageActions.checkAuthKeyForServer, checkAuthKeyForServer);
}
