import classNames from 'classnames/bind';
import styles from './icon.module.scss';

const classesContext = classNames.bind(styles);

export enum IconVariant {
  SEARCH = 'search',
  NEXT = 'next',
  PREV = 'prev',
  USER = 'user',
  HELP = 'help',
  ARROW = 'arrow',
  ARROW_LEFT = 'arrowleft',
  DROPDOWN = 'dropdown',
  LOCK = 'lock',
  PDF = 'pdf',
  CHECK = 'check',
  ITEM = 'item',
  CHAT = 'chat',
  ARCHIVE = 'archive',
  CHECKBOX = 'checkbox',
  NEXT_SMALL = 'nextsmall',
  PHONE = 'phone',
  LOCATION = 'location',
  PLANET = 'planet',
  EMAIL = 'email',
  CLOSE = 'close',
  FILE = 'file',
  FILE_LARGE = 'file_large',
  CALENDAR = 'calendar',
  MENU = 'menu',
  FILTER = 'filter',
  DROPUP = 'dropup'
}

export enum IconTheme {
  WHITE = 'white',
  SECONDARY = 'secondary',
  PRIMARY = 'primary',
  GRAY = 'gray',
  DANGER = 'danger',
  BLACK = 'black'
}

export default function Icon({ variant, theme }: { variant: IconVariant; theme?: IconTheme }): JSX.Element {
  return (
    <span
      className={classesContext(['icon', `icon__${variant}`, { icon__mask: !!theme }, { [`icon__${theme}`]: !!theme }])}
    />
  );
}
