import { createSelector } from 'reselect';
import { AppState } from 'src/store/state';
import { UnsubscribePageState } from './state';
import { plainToClass, plainToClassFromExist } from 'class-transformer';
import { ServerError } from '../../../entities/server-errors';
import { DTOGroup } from '../../../enums/dto-group';
import { UnsubscribeData } from 'src/entities/unsubscribe/unsubscribe-data';

const selectFeature = (state: AppState): UnsubscribePageState => state.pages.unsubscribe;

export class UnsubscribePageSelectors {
  public static isSending = createSelector(
    selectFeature,
    (state) => state.isSending
  );

  public static userEmailDeliveryFrequency = createSelector(
    selectFeature,
    (state) => plainToClass(
      UnsubscribeData,
      state.userEmailDeliveryFrequency,
      { groups: [DTOGroup.MAIN], strategy: 'excludeAll' }
    )
  );

  public static error = createSelector(
    selectFeature,
    (state) => plainToClassFromExist(
      new ServerError<UnsubscribeData>(UnsubscribeData),
      state.error,
      {
        groups: [DTOGroup.MAIN],
        strategy: 'excludeAll'
      }
    ) as ServerError<UnsubscribeData>
  );
}
