import { createWrapper } from 'next-redux-wrapper';
import { applyMiddleware, createStore, Store } from 'redux';
import { rootReducer } from './reducer';
import createSagaMiddleware from 'redux-saga';
import { AppState } from './state';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { rootSaga } from './saga';
import { SagaStore } from './types';

const makeStore = (): SagaStore => {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunkMiddleware, sagaMiddleware)));
  (store as SagaStore).sagaTask = sagaMiddleware.run(rootSaga);

  return store;
};

export const store = makeStore();

export const wrapper = createWrapper<Store<AppState>>(() => store, {
  serializeState: (state) => JSON.stringify(state),
  deserializeState: (state) => JSON.parse(state)
});
