export class ProfileState {
  public profile: object | null;
  public isRefreshing: boolean;
  public isAgreementSigned: boolean;
  public error: object | null;

  constructor() {
    this.profile = null;
    this.isRefreshing = false;
    this.isAgreementSigned = false;
    this.error = null;
  }
}
