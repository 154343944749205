import { Action, createReducer } from 'deox';
import { SellerPortalPageActions } from './actions';
import { SellerPortalPageState } from './state';
import { classToPlain } from 'class-transformer';
import { DTOGroup } from '../../../enums/dto-group';
import { getUploadingFilesProgress } from 'src/utils/get-uploading-files-progress';

const initialState = new SellerPortalPageState();

const reducer = createReducer(initialState, (handleAction) => [
  handleAction(SellerPortalPageActions.resetState, () => initialState),
  handleAction(SellerPortalPageActions.create, (state) => ({
    ...state,
    error: null,
    isSending: true
  })),
  handleAction(SellerPortalPageActions.createSuccess, (state) => ({
    ...state,
    isSubmitted: true,
    isSending: false
  })),
  handleAction(SellerPortalPageActions.createFailure, (state, { payload }) => ({
    ...state,
    error: classToPlain(payload.error, { groups: [DTOGroup.CREATE], strategy: 'excludeAll' }),
    isSending: false,
    isSubmitted: false
  })),
  handleAction(SellerPortalPageActions.uploadFileSuccess, (state, { payload }) => ({
    ...state,
    files: state.files.map((item) => item.file === payload.file ? { ...item, isUploaded: true, media: payload.media } : item),
    isSending: state.files.every((file) => file.isUploaded)
  })),
  handleAction(SellerPortalPageActions.uploadFileProgress, (state, { payload }) => ({
    ...state,
    files: getUploadingFilesProgress(state.files, payload),
    isSending: true
  })),
  handleAction(SellerPortalPageActions.uploadFileFailure, (state, { payload }) => ({
    ...state,
    files: state.files.filter((item) => item.file !== payload.file),
    isSending: false
  })),
  handleAction(SellerPortalPageActions.deleteUploadFile, (state, { payload }) => ({
    ...state,
    files: state.files.filter((item) => item.file !== payload.file),
    isSending: false
  }))
]);

export function sellerPortalPageReducer(
  state: SellerPortalPageState | undefined,
  action: Action<any>
): SellerPortalPageState {
  return reducer(state, action);
}
