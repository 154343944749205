import { IconVariant } from 'src/components/ui-kit/icon/icon';
import { useTranslation } from 'next-i18next';
import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { useSelector } from 'react-redux';
import { AuthSelectors } from 'src/store/auth/selectors';
import styles from './layout.module.scss';
import LayoutHeaderLink from './_header_link';

export default function LayoutHeaderLinks({
  className,
  ...restProps
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>): JSX.Element {
  const { t } = useTranslation(['components:layout']);
  const isAuthenticated = useSelector(AuthSelectors.isAuthenticated);

  return (
    <div
      className={styles.header_links}
      {...restProps}>
      {!isAuthenticated && (
        <LayoutHeaderLink
          href='/blog/create-account-us-mineral-exchange'
          iconVariant={IconVariant.USER}>
          {t('HEADER.LINK_CREATE_ACCOUNT')}
        </LayoutHeaderLink>
      )}
      {isAuthenticated && (
        <LayoutHeaderLink
          href='/profile'
          iconVariant={IconVariant.USER}>
          {t('HEADER.LINK_MY_ACCOUNT')}
        </LayoutHeaderLink>
      )}
      <LayoutHeaderLink
        href='/blog/sell-mineral-rights/common-mineral-owner-questions-and-answers'
        iconVariant={IconVariant.HELP}
      >
        {t('HEADER.LINK_HELP')}
      </LayoutHeaderLink>
      <LayoutHeaderLink
        href='/blog/industry-news/free-consultation/'
        iconVariant={IconVariant.CHAT}>
        {t('HEADER.LINK_FREE_CONSULTATION')}
      </LayoutHeaderLink>
    </div>
  );
}
