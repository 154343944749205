import { Expose } from 'class-transformer';
import { DTOGroup } from 'src/enums/dto-group';

export class RestorePasswordData {
  @Expose({ groups: [DTOGroup.MAIN] })
  public token: string;

  @Expose({ groups: [DTOGroup.MAIN] })
  public password: string;

  constructor(model: Partial<RestorePasswordData> = {}) {
    Object.assign(this, model);
  }
}
