import { AuthResponse, User } from 'src/entities/user';
import { action, actionWithPayload } from 'src/store/action-factory';

export class AuthActions {
  public static authorize = actionWithPayload<{ user: User }>(
    '[Auth] Authorize'
  );

  public static authorizeSuccess = actionWithPayload<{ response: AuthResponse }>(
    '[Auth] Authorize Success'
  );

  public static authorizeFailure = action(
    '[Auth] Authorize Failure'
  );

  public static unauthorize = action(
    '[Auth] Unauthorize'
  );

  public static setIsAuthenticated = actionWithPayload<{ isAuthenticated: boolean }>(
    '[Auth] Set Is Authenticated'
  );
}
