import { ListingInquiry } from 'src/entities/listing-inquiry';
import { ServerError } from 'src/entities/server-errors';
import { action, actionWithPayload } from 'src/store/action-factory';

export class ListingInquiryPageActions {
  public static resetState = action(
    '[ListingInquiryPage] Reset state'
  );

  public static create = actionWithPayload<{ listingInquiry: ListingInquiry }>(
    '[ListingInquiryPage] Create'
  );

  public static createSuccess = action(
    '[ListingInquiryPage] Create success'
  );

  public static createFailure = actionWithPayload<{ error: ServerError<ListingInquiry> | null }>(
    '[ListingInquiryPage] Create Failure'
  );
}
