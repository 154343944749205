import { Exclude, Expose, Type } from 'class-transformer';
import { DTOGroup } from 'src/enums/dto-group';
import { UserEmailDeliveryFrequency, UserLegalType, UserRole, UserStatus, UserType } from 'src/enums/user';
import { DateTime } from 'luxon';
import { TransformDate } from 'src/utils/transform-date';
import { BaseEntity } from '../base-entity';
import { LegalAgreement } from './legal-agreement';
import { toUpper } from 'lodash';

export class User extends BaseEntity {
  @Expose({ name: 'first_name', groups: [DTOGroup.MAIN, DTOGroup.CREATE, DTOGroup.UPDATE] })
  public firstName: string;

  @Expose({ name: 'last_name', groups: [DTOGroup.MAIN, DTOGroup.CREATE, DTOGroup.UPDATE] })
  public lastName: string;

  @Expose({ groups: [DTOGroup.MAIN, DTOGroup.CREATE] })
  public username: string;

  @Expose({ groups: [DTOGroup.MAIN, DTOGroup.CREATE] })
  public email: string;

  @Expose({ name: 'username_or_email', groups: [DTOGroup.MAIN] })
  public userNameOrEmail: string;

  @Expose({ name: 'role_id', groups: [DTOGroup.MAIN] })
  public roleID: UserRole;

  @Expose({ groups: [DTOGroup.MAIN, DTOGroup.CREATE] })
  public password: string;

  @Expose({ groups: [DTOGroup.CREATE] })
  public confirm: string;

  @Expose({ groups: [DTOGroup.MAIN, DTOGroup.CREATE, DTOGroup.UPDATE] })
  public company: string;

  @Expose({ groups: [DTOGroup.MAIN, DTOGroup.CREATE, DTOGroup.UPDATE] })
  public address: string;

  @Expose({ groups: [DTOGroup.MAIN, DTOGroup.CREATE, DTOGroup.UPDATE] })
  public city: string;

  @Expose({ groups: [DTOGroup.MAIN, DTOGroup.CREATE, DTOGroup.UPDATE] })
  public state: string;

  @Expose({ name: 'zip_code', groups: [DTOGroup.MAIN, DTOGroup.CREATE, DTOGroup.UPDATE] })
  public zipCode: string;

  @Expose({ groups: [DTOGroup.MAIN, DTOGroup.CREATE, DTOGroup.UPDATE] })
  public phone: string;

  @Expose({ name: 'ip_address', groups: [DTOGroup.MAIN] })
  public ipAddress: string;

  @Expose({ name: 'legal_type', groups: [DTOGroup.MAIN, DTOGroup.CREATE] })
  public legalType: UserLegalType;

  @Expose({ groups: [DTOGroup.MAIN] })
  public type: UserType;

  @Expose({ groups: [DTOGroup.MAIN] })
  public status: UserStatus;

  @Expose({ name: 'set_password_hash_created_at', groups: [DTOGroup.MAIN] })
  @Type(() => DateTime)
  @TransformDate()
  public setPasswordHashCreatedAt: DateTime;

  @Expose({ name: 'logged_in_at', groups: [DTOGroup.MAIN] })
  @Type(() => DateTime)
  @TransformDate()
  public loggedInAt: DateTime;

  @Expose({ name: 'approved_at', groups: [DTOGroup.MAIN] })
  @Type(() => DateTime)
  @TransformDate()
  public approvedAt: DateTime;

  @Expose({ name: 'legal_agreement', groups: [DTOGroup.MAIN] })
  @Type(() => LegalAgreement)
  public legalAgreement: LegalAgreement;

  @Expose({ name: 'email_delivery_frequency', groups: [DTOGroup.MAIN, DTOGroup.UPDATE] })
  public emailDeliveryFrequency: UserEmailDeliveryFrequency;

  @Exclude()
  public get statusInUpperCase(): string {
    return toUpper(this.status);
  }

  @Exclude()
  public get isActive(): boolean {
    return this.status === UserStatus.ACTIVE;
  }

  @Exclude()
  public get isInactive(): boolean {
    return this.status === UserStatus.INACTIVE;
  }

  constructor(model: Partial<User> = {}) {
    super(model);

    Object.assign(this, model);
  }
}
