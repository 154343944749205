import { ListingOrderBy, ListingRelation } from 'src/enums/listing';
import { listingService } from 'src/services/listing';
import { stateService } from 'src/services/state';
import { MineralListingsPageActions } from './actions';
import { MineralListingsPageSelectors } from './selectors';
import { Action } from 'deox';
import { SagaIterator } from 'redux-saga';
import { takeLatest, call, put, select, fork, FixedTask } from 'typed-redux-saga';
import { ListingFiltersQueryParameters } from 'src/entities/listing/query-filters-parameters';
import { StateOrderBy, StateRelation } from '../../../enums/state';
import { configuration } from '../../../configurations/configuration';
import { ListingFilters } from 'src/entities/listing';

export let loadListingsTask: FixedTask<typeof loadListings>;
export let loadStatesTask: FixedTask<typeof loadStates>;

function* loadListings({
  payload
}: Action<string, typeof MineralListingsPageActions.loadListings.payload>): SagaIterator {
  const currentPage = yield* select(MineralListingsPageSelectors.currentPage);
  const perPage = yield* select(MineralListingsPageSelectors.perPage);

  try {
    const response = yield* call(listingService.search, {
      page: payload.page ?? currentPage,
      perPage: payload.perPage ?? perPage,
      orderBy: payload.order ?? ListingOrderBy.WEIGHT,
      desc: payload.desc ?? true,
      relations: payload.relations ?? [ListingRelation.COUNTY, ListingRelation.STATE],
      filters: new ListingFilters({ ...payload.filters })
    });

    yield* put(MineralListingsPageActions.loadListingsSuccess({ response }));
  } catch (error) {
    yield* put(MineralListingsPageActions.loadListingsFailure({ error }));
  }
}

function* loadListingsForServer({
  type,
  payload
}: Action<string, typeof MineralListingsPageActions.loadListingsForServer.payload>): SagaIterator {
  yield* call(function* () {
    loadListingsTask = yield* fork(loadListings, {
      type,
      payload: {
        page: payload.page,
        perPage: configuration.pagination.defaultPerPage,
        order: payload.order,
        desc: payload.desc,
        filters: new ListingFiltersQueryParameters({
          statesIDs: payload.stateID,
          countiesIDs: payload.countyID
        })
      }
    });
  });
}

function* loadStates(): SagaIterator {
  try {
    const response = yield* call(stateService.search, {
      all: true,
      relations: [StateRelation.COUNTIES],
      orderBy: StateOrderBy.NAME
    });

    yield* put(MineralListingsPageActions.loadStatesSuccess({ response }));
  } catch (error) {
    yield* put(MineralListingsPageActions.loadStatesFailure({ error }));
  }
}

function* loadStatesForServer(): SagaIterator {
  yield* call(function* () {
    loadStatesTask = yield* fork(loadStates);
  });
}

export default function* mineralListingsPageSaga(): SagaIterator {
  yield* takeLatest(MineralListingsPageActions.loadListings, loadListings);
  yield* takeLatest(MineralListingsPageActions.loadListingsForServer, loadListingsForServer);
  yield* takeLatest(MineralListingsPageActions.loadStates, loadStates);
  yield* takeLatest(MineralListingsPageActions.loadStatesForServer, loadStatesForServer);
}
