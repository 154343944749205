import { Listing } from 'src/entities/listing';
import { PaginationResponse } from 'src/entities/pagination';
import { ServerError } from 'src/entities/server-errors';
import { action, actionWithPayload } from 'src/store/action-factory';

export class FeaturedListingsActions {
  public static resetState = action(
    '[FeaturedListings] Reset state'
  );

  public static loadFeaturedListings = action(
    '[FeaturedListings] Load Featured Listings'
  );

  public static loadFeaturedListingsForServer = action(
    '[FeaturedListings] Load Featured Listings for Server'
  );

  public static loadFeaturedListingsSuccess = actionWithPayload<{ response: PaginationResponse<Listing> }>(
    '[FeaturedListings] Load Featured Listings Success'
  );

  public static loadFeaturedListingsFailure = actionWithPayload<{ error: ServerError<any> }>(
    '[FeaturedListings] Load Featured Listings Failure'
  );
}
