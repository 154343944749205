import Router from 'next/router';
import { User } from 'src/entities/user';
import { profileService } from 'src/services/profile';
import { ProfileActions } from 'src/store/profile/actions';
import { ProfilePageActions } from './actions';
import { Action } from 'deox';
import { SagaIterator } from 'redux-saga';
import { takeLatest, call, put } from 'typed-redux-saga';
import { toast } from 'react-toastify';
import { i18n as i18next } from 'next-i18next';
import { i18n } from 'next-i18next.config';

function* edit({ payload }: Action<string, typeof ProfilePageActions.edit.payload>): SagaIterator {
  try {
    yield* call(profileService.update, payload.profile);

    yield* put(ProfilePageActions.editSuccess());
    yield* put(ProfileActions.refresh());

    const t = i18next?.getFixedT(i18n.defaultLocale, 'components:pages:profile:edit-account');
    if (t) {
      toast.success(t(payload.messageSuccess ?? 'TEXT_MESSAGE_SUCCESS') as string);
    }
  } catch (error) {
    yield* put(ProfilePageActions.editFailure({ error }));
  }
}

function* changePassword({ payload }: Action<string, typeof ProfilePageActions.changePassword.payload>): SagaIterator {
  try {
    yield* call(profileService.update, new User(payload.data));
    Router.push('/profile');

    yield* put(ProfilePageActions.changePasswordSuccess());
  } catch (error) {
    yield* put(ProfilePageActions.changePasswordFailure({ error }));
  }
}

export default function* profilePageSaga(): SagaIterator {
  yield* takeLatest(ProfilePageActions.edit, edit);
  yield* takeLatest(ProfilePageActions.changePassword, changePassword);
}
