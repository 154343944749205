import { stateService } from 'src/services/state';
import { SignAccessAgreementPageActions } from './actions';
import { SagaIterator } from 'redux-saga';
import { signAgreementService } from 'src/services/sign-agreement';
import { toast } from 'react-toastify';
import { i18n as i18next } from 'next-i18next';
import { i18n } from 'next-i18next.config';
import { Action } from 'deox';
import { takeLatest, call, put, fork, FixedTask } from 'typed-redux-saga';
import Router from 'next/router';
import { ProfileActions } from 'src/store/profile';
import { StateOrderBy } from 'src/enums/state';

export let loadStatesTask: FixedTask<typeof loadStates>;

function* loadStates(): SagaIterator {
  try {
    const response = yield* call(stateService.search, { all: true, orderBy: StateOrderBy.NAME });

    yield* put(SignAccessAgreementPageActions.loadStatesSuccess({ response }));
  } catch (error) {
    yield* put(SignAccessAgreementPageActions.loadStatesFailure({ error }));
  }
}

function* loadStatesForServer(): SagaIterator {
  yield* call(function* () {
    loadStatesTask = yield* fork(loadStates);
  });
}

function* signAgreement({
  payload
}: Action<string, typeof SignAccessAgreementPageActions.signAgreement.payload>): SagaIterator {
  try {
    const response = yield* call(signAgreementService.signAgreement, payload.agreement);

    yield* put(SignAccessAgreementPageActions.signAgreementSuccess({ response }));
    yield* put(ProfileActions.refresh());

    Router.push('/api/disable-preview');
  } catch (error) {
    yield* put(SignAccessAgreementPageActions.signAgreementFailure({ error }));
  }
}

function* signAgreementFailure(): SagaIterator {
  const t = i18next?.getFixedT(i18n.defaultLocale, 'components:pages:sign-access-agreement:form');

  if (t) {
    yield* call(toast.error, t('ERRORS.TEXT_SENDING_ERROR'));
  }
}

export default function* signAccessAgreementPageSaga(): SagaIterator {
  yield* takeLatest(SignAccessAgreementPageActions.loadStates, loadStates);
  yield* takeLatest(SignAccessAgreementPageActions.loadStatesForServer, loadStatesForServer);
  yield* takeLatest(SignAccessAgreementPageActions.signAgreement, signAgreement);
  yield* takeLatest(SignAccessAgreementPageActions.signAgreementFailure, signAgreementFailure);
  yield* takeLatest(SignAccessAgreementPageActions.loadStates, loadStates);
  yield* takeLatest(SignAccessAgreementPageActions.loadStatesForServer, loadStatesForServer);
}
