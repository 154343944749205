import { Expose, Type } from 'class-transformer';
import { DateTime } from 'luxon';
import { DTOGroup } from 'src/enums/dto-group';
import { EntityType, UserBusinessType } from 'src/enums/sign-agreement';
import { TransformDate } from 'src/utils/transform-date';

export class SignAgreementData {
  @Expose({ groups: [DTOGroup.CREATE] })
  public type: UserBusinessType;

  @Expose({ groups: [DTOGroup.CREATE] })
  public name: string;

  @Expose({ name: 'name_of_authorized', groups: [DTOGroup.CREATE] })
  public nameOfAuthorized: string;

  @Expose({ name: 'agreement_signed_at', groups: [DTOGroup.CREATE] })
  @Type(() => DateTime)
  @TransformDate()
  public agreementSignedAt: DateTime;

  @Expose({ name: 'state_id', groups: [DTOGroup.CREATE] })
  public stateId: number;

  @Expose({ name: 'entity_type', groups: [DTOGroup.CREATE] })
  public entityType: EntityType;

  constructor(model: Partial<SignAgreementData> = {}) {
    Object.assign(this, model);
  }
}
