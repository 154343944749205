import { SagaIterator } from 'redux-saga';
import { call, takeLatest } from 'typed-redux-saga';
import { ServerErrorMessageActions } from './actions';
import { Action } from 'deox';
import { configuration } from '../../configurations/configuration';
import { destroyCookie, setCookie } from 'nookies';
import { serialize } from 'class-transformer';

function* setMessage({ payload }: Action<string, typeof ServerErrorMessageActions.setMessage.payload>): SagaIterator {
  yield* call(
    {
      context: setCookie,
      fn: setCookie
    },
    null,
    configuration.cookies.keys.serverErrorMessage,
    serialize(payload.error),
    { path: '/' }
  );
}

function* removeMessage(): SagaIterator {
  yield* call(
    {
      context: destroyCookie,
      fn: destroyCookie
    },
    null,
    configuration.cookies.keys.serverErrorMessage,
    { path: '/' }
  );
}

export default function* serverErrorMessageSaga(): SagaIterator {
  yield* takeLatest(ServerErrorMessageActions.setMessage, setMessage);
  yield* takeLatest(ServerErrorMessageActions.removeMessage, removeMessage);
}
