import { configuration } from 'src/configurations/configuration';

export class MineralListingsPageState {
  public isLoading: boolean;
  public items: Array<object>;
  public total: number;
  public currentPage: number;
  public perPage: number;
  public states: Array<object>;
  public isStatesLoading: boolean;

  constructor() {
    this.isLoading = false;
    this.items = [];
    this.total = 0;
    this.currentPage = 1;
    this.perPage = configuration.pagination.defaultPerPage;
    this.states = [];
    this.isStatesLoading = false;
  }
}
