import { PaginationRequest } from 'src/entities/pagination';
import { StateOrderBy, StateRelation, StateWithCount } from 'src/enums/state';

export class StatePaginationRequest extends PaginationRequest<StateRelation, StateOrderBy, StateWithCount> {
  constructor(model: Partial<StatePaginationRequest> = {}) {
    super(model);

    Object.assign(this, model);
  }
}
