import classNames from 'classnames/bind';
import ButtonTab from 'src/components/ui-kit/button/__tab';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { useSelector } from 'react-redux';
import { AuthSelectors } from 'src/store/auth/selectors';
import styles from './layout.module.scss';
import LayoutSearch from './_search';

const classesContext = classNames.bind(styles);

export default function LayoutMenu({
  className,
  isFullScreenSearchOpen,
  ...restProps
}: { isFullScreenSearchOpen: boolean } & DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>): JSX.Element {
  const { t } = useTranslation(['components:layout']);
  const { pathname } = useRouter();

  const isAuthenticated = useSelector(AuthSelectors.isAuthenticated);

  const items = [
    { label: t('MENU.LINK_SELLERS'), href: '/blog/sell-mineral-rights/welcome-to-us-mineral-exchange' },
    {
      label: t('MENU.LINK_BUYERS'),
      href: isAuthenticated ? '/profile' : '/buy-mineral-rights/create-buyers-account',
      routes: ['/profile', '/buy-mineral-rights/create-buyers-account', '/sign-up']
    },
    {
      label: t('MENU.LINK_LISTINGS'),
      href: '/mineral-listings',
      routes: ['/listings', '/mineral-listings']
    },
    { label: t('MENU.LINK_EDUCATION'), href: '/blog/sell-mineral-rights/guide-to-mineral-rights-ownership' },
    { label: t('MENU.LINK_BLOG'), href: '/blog' },
    { label: t('MENU.LINK_CONTACT'), href: '/contact-US-Mineral-Exchange', routes: ['/contact'] }
  ];

  return (
    <div
      className={classesContext(['menu', { menu_search: isFullScreenSearchOpen }, className])}
      {...restProps}>
      <div className={classesContext(styles.menu_items, 'menu_inner')}>
        <Link
          passHref
          href='/'>
          <ButtonTab
            isActive={pathname === '/'}
            className={classesContext([styles.menu_item, { menu_item__active: pathname === '/' }])}
          >
            {t('MENU.LINK_HOME')}
          </ButtonTab>
        </Link>
        {items?.map((item, key) => (
          <Link
            passHref
            key={key}
            href={item.href}>
            <ButtonTab
              isActive={item?.routes?.some((route) => pathname.includes(route)) ?? false}
              className={classesContext([
                styles.menu_item,
                { menu_item__active: item?.routes?.some((route) => pathname.includes(route)) ?? false }
              ])}
            >
              {item.label}
            </ButtonTab>
          </Link>
        ))}
      </div>
      <div className={styles.menu_filters}>
        <LayoutSearch placeholder={t('MENU.TEXT_LISTING_NUMBER')} />
      </div>
    </div>
  );
}
