import { Exclude, Expose } from 'class-transformer';
import { DTOGroup } from 'src/enums/dto-group';
import { BaseEntity } from '../base-entity';
import { NavigationType } from '../../enums/navigation/type';

export class Navigation extends BaseEntity {
  @Expose({ groups: [DTOGroup.MAIN] })
  public path: string;

  @Expose({ groups: [DTOGroup.MAIN] })
  public name: string;

  @Expose({ groups: [DTOGroup.MAIN] })
  public position: string;

  @Expose({ groups: [DTOGroup.MAIN] })
  public type: NavigationType;

  @Expose({ groups: [DTOGroup.MAIN] })
  public link: string;

  @Expose({ name: 'btn_text', groups: [DTOGroup.MAIN] })
  public btnText: string;

  @Expose({ groups: [DTOGroup.MAIN] })
  public title: string;

  @Expose({ groups: [DTOGroup.MAIN] })
  public text: string;

  @Expose({ name: 'media_id', groups: [DTOGroup.MAIN] })
  public mediaId: string;

  @Exclude()
  public get linkImage(): string {
    return `${process.env.NEXT_PUBLIC_API_URL}/media/${this.mediaId}`;
  }

  constructor(model: Partial<Navigation> = {}) {
    super(model);

    Object.assign(this, model);
  }
}
