import { TFunction } from 'next-i18next';
import * as Yup from 'yup';

export class LoginForm {
  public userNameOrEmail: string;
  public password: string;

  constructor() {
    this.userNameOrEmail = '';
    this.password = '';
  }

  public static getValidationSchema(t: TFunction): Yup.SchemaOf<LoginForm> {
    return Yup.object()
      .shape({
        userNameOrEmail: Yup.string()
          .required(t('ERRORS.TEXT_USER_NAME_OR_EMAIL_REQUIRED')),
        password: Yup.string()
          .required(t('ERRORS.TEXT_PASSWORD_REQUIRED'))
      });
  }
}
