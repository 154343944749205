import { Expose } from 'class-transformer';
import { DTOGroup } from 'src/enums/dto-group';

export class SellerPortalData {
  @Expose({ name: 'seller_name', groups: [DTOGroup.CREATE] })
  public sellerName: string;

  @Expose({ name: 'seller_email', groups: [DTOGroup.CREATE] })
  public sellerEmail: string;

  @Expose({ name: 'seller_phone', groups: [DTOGroup.CREATE] })
  public sellerPhone: string;

  @Expose({ name: 'asking_price', groups: [DTOGroup.CREATE] })
  public askingPrice: string;

  @Expose({ name: 'auction_term', groups: [DTOGroup.CREATE] })
  public auctionTerm: string;

  @Expose({ name: 'effective_date', groups: [DTOGroup.CREATE] })
  public effectiveDate: string;

  @Expose({ name: 'due_diligence_period', groups: [DTOGroup.CREATE] })
  public dueDiligencePeriod: string;

  @Expose({ name: 'other_due_diligence_period', groups: [DTOGroup.CREATE] })
  public otherDueDiligencePeriod: string;

  @Expose({ name: 'curative_extension_allowable', groups: [DTOGroup.CREATE] })
  public curativeExtensionAllowable: string;

  @Expose({ name: 'other_curative_extension_allowable', groups: [DTOGroup.CREATE] })
  public otherCurativeExtensionAllowable: string;

  @Expose({ name: 'deed_type', groups: [DTOGroup.CREATE] })
  public deedType: string;

  @Expose({ name: 'sale_type', groups: [DTOGroup.CREATE] })
  public saleType: string;

  @Expose({ name: 'other_information', groups: [DTOGroup.CREATE] })
  public otherInformation: string;

  @Expose({ name: 'media_ids', groups: [DTOGroup.CREATE] })
  public mediaIDs: Array<number> | null;

  constructor(model: Partial<SellerPortalData> = {}) {
    Object.assign(this, model);
  }
}
