import { Action, createReducer } from 'deox';
import { SignAccessAgreementPageActions } from './actions';
import { SignAccessAgreementPageState } from './state';
import { AppActions } from 'src/store/actions';
import { classToPlain } from 'class-transformer';
import { DTOGroup } from 'src/enums/dto-group';

const initialState = new SignAccessAgreementPageState();

const reducer = createReducer(initialState, (handleAction) => [
  handleAction(SignAccessAgreementPageActions.resetState, () => initialState),
  handleAction(AppActions.hydrate, (state, { payload }) => ({
    ...state,
    states: payload.pages.signAccessAgreement.states
  })),
  handleAction(SignAccessAgreementPageActions.loadStates, (state) => ({
    ...state,
    isStatesLoading: true
  })),
  handleAction(SignAccessAgreementPageActions.loadStatesSuccess, (state, { payload }) => ({
    ...state,
    states: classToPlain(payload.response.data, { groups: [DTOGroup.MAIN], strategy: 'excludeAll' }) as Array<
      Record<string, any>
    >,
    isStatesLoading: false
  })),
  handleAction(SignAccessAgreementPageActions.loadStatesFailure, (state) => ({
    ...state,
    isStatesLoading: false
  })),
  handleAction(SignAccessAgreementPageActions.signAgreement, (state) => ({
    ...state,
    error: null,
    isSending: true
  })),
  handleAction(SignAccessAgreementPageActions.signAgreementSuccess, (state) => ({
    ...state,
    isSending: false
  })),
  handleAction(SignAccessAgreementPageActions.signAgreementFailure, (state, { payload }) => ({
    ...state,
    error: classToPlain(payload.error, { groups: [DTOGroup.MAIN], strategy: 'excludeAll' }),
    isSending: false
  }))
]);

export function signAccessAgreementPageReducer(
  state: SignAccessAgreementPageState | undefined,
  action: Action<any>
): SignAccessAgreementPageState {
  return reducer(state, action);
}
