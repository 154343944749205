import classNames from 'classnames/bind';
import Icon, { IconVariant } from 'src/components/ui-kit/icon/icon';
import { FormikProps, FormikValues } from 'formik';
import { get } from 'lodash';
import { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import styles from './form.module.scss';
import FormError from './_error';

const classesContext = classNames.bind(styles);

export type FormTextInputProps<T = FormikValues> = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  formik?: FormikProps<T>;
  isAsteriskShown?: boolean;
  isLockShown?: boolean;
  isBordered?: boolean;
  hiddenError?: boolean;
};

export type InputValue = string | number | undefined | ReadonlyArray<string>;

export function getValue<T>(formik: FormikProps<T> | undefined, name: string | undefined): InputValue {
  if (formik && name) {
    return get(formik.values, name);
  }
}

export function getTouched<T>(formik: FormikProps<T> | undefined, name: string | undefined): Array<string> | undefined {
  if (formik && name) {
    return get(formik.touched, name);
  }
}

export function getError<T>(formik: FormikProps<T> | undefined, name: string | undefined): string | undefined {
  if (formik && name && getTouched(formik, name)) {
    return get(formik.errors, name);
  }
}

export function onBlur<T>(formik: FormikProps<T> | undefined, name: string | undefined): void {
  if (formik && name && getTouched(formik, name)) {
    formik.getFieldHelpers(name)
      ?.setTouched(true);
  }
}

export default function FormTextInput<T>({
  type,
  formik,
  name,
  isAsteriskShown,
  isBordered,
  isLockShown,
  hiddenError,
  className,
  ...inputProps
}: FormTextInputProps<T>): JSX.Element {
  const error = getError<T>(formik, name);

  return (
    <>
      <div
        className={classesContext([
          'form_group',
          {
            form_group__required: isAsteriskShown,
            form_group__bordered: isBordered,
            form_group__error: error
          },
          className
        ])}
      >
        <div className={classesContext({ form_group_lock: isLockShown })}>
          <input
            onChange={formik?.handleChange}
            onBlur={() => onBlur(formik, name)}
            name={name}
            value={getValue<T>(formik, name) || ''}
            type={type ?? 'text'}
            className={styles.form_control}
            {...inputProps}
          />
          {isLockShown && (
            <span className={styles.form_group__disabled}>
              <Icon variant={IconVariant.LOCK} />
            </span>
          )}
        </div>
        {!hiddenError && error && <FormError error={error} />}
      </div>
    </>
  );
}
