import { plainToClass } from 'class-transformer';
import { SignAgreementResponse } from 'src/entities/user/sign-agreement-response';
import { DTOGroup } from 'src/enums/dto-group';
import { apiServerService } from './api';

export class UserService {
  public async checkSignedAgreement(userId: string): Promise<SignAgreementResponse> {
    const response = await apiServerService.get<SignAgreementResponse>(`/users/${userId}/agreement`);

    return plainToClass(SignAgreementResponse, response, {
      groups: [DTOGroup.MAIN],
      strategy: 'excludeAll'
    });
  }
}

export const userService = new UserService();
