import { Action, createReducer } from 'deox';
import { UnsubscribePageActions } from './actions';
import { UnsubscribePageState } from './state';
import { classToPlain } from 'class-transformer';
import { DTOGroup } from '../../../enums/dto-group';
import { AppActions } from 'src/store/actions';

const initialState = new UnsubscribePageState();

const reducer = createReducer(initialState, (handleAction) => [
  handleAction(UnsubscribePageActions.resetState, () => initialState),
  handleAction(AppActions.hydrate, (state, { payload }) => ({
    ...state,
    userEmailDeliveryFrequency: payload.pages.unsubscribe.userEmailDeliveryFrequency
  })),
  handleAction(UnsubscribePageActions.unsubscribe, (state) => ({
    ...state,
    error: null,
    isSending: true
  })),
  handleAction(UnsubscribePageActions.unsubscribeSuccess, (state) => ({
    ...state,
    isSending: false
  })),
  handleAction(UnsubscribePageActions.unsubscribeFailure, (state, { payload }) => ({
    ...state,
    error: classToPlain(payload.error, { groups: [DTOGroup.MAIN], strategy: 'excludeAll' }),
    isSending: false
  })),
  handleAction(UnsubscribePageActions.checkAuthKeySuccess, (state, { payload }) => ({
    ...state,
    userEmailDeliveryFrequency: classToPlain(payload.response, { groups: [DTOGroup.MAIN], strategy: 'excludeAll' }),
    error: null
  })),
  handleAction(UnsubscribePageActions.checkAuthKeyFailure, (state, { payload }) => ({
    ...state,
    error: classToPlain(payload.error, { groups: [DTOGroup.MAIN], strategy: 'excludeAll' })
  }))
]);

export function unsubscribePageReducer(
  state: UnsubscribePageState | undefined,
  action: Action<any>
): UnsubscribePageState {
  return reducer(state, action);
}
