import { Expose } from 'class-transformer';
import { ListingRelation } from 'src/enums/listing';

export class ListingRetrieveRequest {
  @Expose({ name: 'with', toPlainOnly: true })
  public relations?: Array<ListingRelation>;

  constructor(model: Partial<ListingRetrieveRequest> = {}) {
    Object.assign(this, model);
  }
}
