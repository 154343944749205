import { Expose, Type } from 'class-transformer';
import { DateTime } from 'luxon';
import { DTOGroup } from 'src/enums/dto-group';
import { TransformDate } from 'src/utils/transform-date';

export class BaseEntity {
  @Expose({ groups: [DTOGroup.MAIN] })
  public id: number;

  @Expose({ name: 'created_at', groups: [DTOGroup.MAIN] })
  @Type(() => DateTime)
  @TransformDate()
  public createdAt: DateTime;

  @Expose({ name: 'updated_at', groups: [DTOGroup.MAIN] })
  @Type(() => DateTime)
  @TransformDate()
  public updatedAt: DateTime;

  constructor(model: Partial<BaseEntity> = {}) {
    Object.assign(this, model);
  }
}
