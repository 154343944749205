import { useRouter } from 'next/router';
import { ListingOrderBy } from '../enums/listing';
import { OrderByDirection } from '../enums/order-by-direction';
import { stringify } from 'querystring';
import { castArray, isEmpty, pick } from 'lodash';
import { useSelector } from 'react-redux';
import { MineralListingsPageSelectors } from '../store/pages/mineral-listings';
import { ListingFilterKeys } from 'src/enums/listing/filter-keys';
import { isSlugContainsPage, prepareListingRouteSlug } from 'src/utils/prepare-listing-route-slug';
import { AuthSelectors } from 'src/store/auth';

export type RouterListingsPageSlug = {
  page?: number;
  state?: string;
  county?: string;
  orderBy?: ListingOrderBy;
  orderByDirection?: OrderByDirection;
};

export type RouterListingsPageQueryParams = {
  perPage?: number;
  statesIDs?: Array<number>;
  countiesIDs?: Array<number>;
  isCashFlow?: boolean;
  isShowSalePending?: boolean;
  listings?: ListingFilterKeys;
  query?: string;
};

export type RouterListingsPageRawQueryParams = { [key in keyof RouterListingsPageQueryParams]: string | Array<string> };
export type RouterListingsPageRawQueryParamsKeys = keyof RouterListingsPageRawQueryParams;

export type RouterListingsPageFilters = {
  statesIDs?: Array<number> | null;
  countiesIDs?: Array<number> | null;
  isCashFlow?: boolean | null;
  isShowSalePending?: boolean | null;
  listings?: ListingFilterKeys | null;
  query?: string | null;
};

export type RouterListingsPageParams<T = undefined> = {
  page?: number | T;
  perPage?: number | T;
  orderBy?: ListingOrderBy | T;
  orderByDirection?: OrderByDirection | T;
  filters?: RouterListingsPageFilters | T;
};

export const routerListingsQueryParamsKeys: Array<RouterListingsPageRawQueryParamsKeys> = [
  'perPage',
  'statesIDs',
  'countiesIDs',
  'isCashFlow',
  'isShowSalePending',
  'listings',
  'query'
];

export function useRouterListings(): {
  buildPageUrl: (params: RouterListingsPageParams<null>) => string;
  getSlug: () => RouterListingsPageSlug;
  getQueryParams: () => RouterListingsPageQueryParams;
  getCurrentPageParams: () => RouterListingsPageParams;
  push: (params: RouterListingsPageParams<null>) => Promise<boolean>;
  } {
  const router = useRouter();
  const states = useSelector(MineralListingsPageSelectors.states);
  const isAuthenticated = useSelector(AuthSelectors.isAuthenticated);

  const getSlug = (): RouterListingsPageSlug => {
    const [slugPage, slugState, slugCounty, slugOrderBy, slugOrderByDirection] = prepareListingRouteSlug(
      castArray(router.query?.slug)
    );

    return {
      page: slugPage ? Number(slugPage.replace('page', '')) : undefined,
      state: slugState,
      county: slugCounty,
      orderBy:
        slugOrderBy && Object.values(ListingOrderBy)
          .includes(slugOrderBy as ListingOrderBy)
          ? (slugOrderBy as ListingOrderBy)
          : undefined,
      orderByDirection: slugOrderByDirection ? (slugOrderByDirection as OrderByDirection) : undefined
    };
  };

  const getQueryParams = (): RouterListingsPageQueryParams => {
    const queryParams = pick(router.query, routerListingsQueryParamsKeys);

    const isEqualStringTrue = (value?: string | Array<string>): boolean | undefined => {
      return value ? value === 'true' : undefined;
    };

    const isEqualStringEnum = (value: string): ListingFilterKeys | undefined => {
      return Object.values(ListingFilterKeys)
        .find((item) => item === value);
    };

    return {
      perPage: queryParams.perPage ? Number(queryParams.perPage) : undefined,
      statesIDs: queryParams.statesIDs ? castArray(queryParams.statesIDs)
        .map((stateID) => Number(stateID)) : undefined,
      countiesIDs: queryParams.countiesIDs
        ? castArray(queryParams.countiesIDs)
          .map((countyID) => Number(countyID))
        : undefined,
      isCashFlow: isEqualStringTrue(queryParams.isCashFlow),
      isShowSalePending: isEqualStringTrue(queryParams.isShowSalePending),
      listings: queryParams.listings ? isEqualStringEnum(queryParams.listings.toString()) : undefined,
      query: queryParams.query ? castArray(queryParams.query)[0] : undefined
    };
  };

  const buildPageUrl = ({
    page,
    perPage,
    orderBy,
    orderByDirection,
    filters
  }: RouterListingsPageParams<null>): string => {
    const existingSlug = getSlug();
    const existingQueryParams = getQueryParams();

    const slug: Array<string> = [];
    const queryParams: RouterListingsPageQueryParams = {};
    const pagePrefix = 'page';

    if (!orderBy && orderBy !== null && existingSlug.orderBy) {
      if (page) {
        slug.push(`${pagePrefix}${page}`);
      } else if (page !== null && existingSlug.page) {
        slug.push(`${pagePrefix}${existingSlug.page}`);
      }
    }

    if (perPage) {
      queryParams.perPage = perPage;
    } else if (perPage !== null && existingQueryParams.perPage) {
      queryParams.perPage = existingQueryParams.perPage;
    }

    if (filters !== null) {
      if (filters?.statesIDs) {
        if (
          filters?.statesIDs.length === 1 &&
          (!filters?.countiesIDs ||
            !filters?.countiesIDs?.length ||
            (filters?.countiesIDs && filters?.countiesIDs?.length === 1))
        ) {
          const state = states.find((state) => state.id === filters?.statesIDs?.[0]);
          if (state) {
            slug.push(state.nameLowerCase);

            const county = state.counties.find((county) => county.id === filters?.countiesIDs?.[0]);
            if (county) {
              slug.push(county.nameInKebabCase);
            }
          }
        }

        if (filters?.statesIDs.length > 1 && (!filters?.countiesIDs || !filters?.countiesIDs?.length)) {
          queryParams.statesIDs = filters?.statesIDs;
        }

        if (
          (filters?.statesIDs.length === 1 && filters?.countiesIDs && filters?.countiesIDs?.length > 1) ||
          (filters?.statesIDs.length > 1 && filters?.countiesIDs && filters?.countiesIDs?.length > 1) ||
          (filters?.statesIDs.length > 1 && filters?.countiesIDs && filters?.countiesIDs?.length === 1)
        ) {
          queryParams.statesIDs = filters?.statesIDs;
          queryParams.countiesIDs = filters?.countiesIDs;
        }
      } else if (filters?.statesIDs !== null) {
        if (existingSlug.state) {
          slug.push(existingSlug.state);
        } else if (existingQueryParams.statesIDs && existingQueryParams.statesIDs.length) {
          queryParams.statesIDs = existingQueryParams.statesIDs;
        }

        if (filters?.countiesIDs !== null) {
          if (existingSlug.county) {
            slug.push(existingSlug.county);
          } else if (existingQueryParams.countiesIDs && existingQueryParams.countiesIDs.length) {
            queryParams.countiesIDs = existingQueryParams.countiesIDs;
          }
        }
      }

      if (filters?.isCashFlow) {
        queryParams.isCashFlow = filters.isCashFlow;
      } else if (!filters && existingQueryParams.isCashFlow) {
        queryParams.isCashFlow = existingQueryParams.isCashFlow;
      }

      if (filters?.isShowSalePending) {
        queryParams.isShowSalePending = filters.isShowSalePending;
      } else if (!filters && existingQueryParams.isShowSalePending) {
        queryParams.isShowSalePending = existingQueryParams.isShowSalePending;
      }

      if (!isAuthenticated && filters?.listings !== ListingFilterKeys.ACTIVE) {
        if (filters?.listings) {
          queryParams.listings = filters.listings;
        } else if (!filters && existingQueryParams.listings) {
          queryParams.listings = existingQueryParams.listings;
        }
      }
    }

    if (filters && filters?.query) {
      queryParams.query = filters.query;
    } else if ((!filters || (filters && filters?.query !== null)) && existingQueryParams.query) {
      queryParams.query = existingQueryParams.query;
    }

    if (orderBy || orderBy !== null) {
      const isSlugWithPage = slug.findIndex((item) => isSlugContainsPage(item)) === -1;

      if (isSlugWithPage) {
        if (page && page >= 1) {
          slug.push(`${pagePrefix}${page}`);
        } else if ((orderBy && orderBy !== null) || existingSlug.orderBy) {
          slug.push('sort');
        }
      }

      if (orderBy) {
        slug.push(orderBy);
      } else if (orderBy !== null && isSlugWithPage) {
        slug.push(existingSlug.orderBy ?? ListingOrderBy.WEIGHT);
      }

      if (orderByDirection) {
        slug.push(orderByDirection);
      } else if (orderByDirection !== null && isSlugWithPage) {
        slug.push(existingSlug.orderByDirection ?? OrderByDirection.DESC);
      }
    }

    let pageUrl = `/mineral-listings/${slug.join('/')}`;
    if (!isEmpty(queryParams)) {
      pageUrl += `?${stringify(queryParams)}`;
    }

    return pageUrl;
  };

  const push = (params: RouterListingsPageParams<null>): Promise<boolean> => {
    const url = buildPageUrl(params);

    return router.push(url, undefined, { shallow: true });
  };

  const getCurrentPageParams = (): RouterListingsPageParams => {
    const slug = getSlug();
    const queryParams = getQueryParams();

    const statesIDs = [];
    const countiesIDs = [];

    if (slug.state) {
      const state = states.find((state) => state.nameLowerCase === slug.state);
      if (state) {
        statesIDs.push(state.id);

        if (slug.county) {
          const county = state.counties.find((county) => county.nameInKebabCase === slug.county);
          if (county) {
            countiesIDs.push(county.id);
          }
        }
      }
    } else {
      if (queryParams.statesIDs) {
        statesIDs.push(...queryParams.statesIDs);
      }

      if (queryParams.countiesIDs) {
        countiesIDs.push(...queryParams.countiesIDs);
      }
    }

    return {
      page: slug.page ?? 1,
      perPage: queryParams.perPage,
      orderBy: slug.orderBy,
      orderByDirection: slug.orderByDirection,
      filters: {
        statesIDs: statesIDs,
        countiesIDs: countiesIDs,
        isCashFlow: queryParams.isCashFlow,
        isShowSalePending: queryParams.isShowSalePending,
        listings: queryParams.listings,
        query: queryParams.query
      }
    };
  };

  return {
    getSlug,
    getQueryParams,
    getCurrentPageParams,
    buildPageUrl,
    push
  };
}
